import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from 'redux/store';
import { capitalize } from 'lodash';

import Pages from 'pages';
import GoogleAnalyticsTracker from 'components/GoogleAnalyticsTracker/GoogleAnalyticsTracker';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { FlightNav } from '@flybits/webapp-design-system-react';
import SnackBar from 'components/SnackBar/SnackBar';
import './App.scss';

const sideBarRoutes = [
  {
    label: 'Home',
    icon: 'subject',
    location: '/',
  },
  {
    label: 'Templated Experiences',
    icon: 'subject',
    location: '/templated-experiences',
  }
];

if (process.env.REACT_APP_ENV === 'development') {
  sideBarRoutes.push({
    label: 'Developer Hub',
    icon: 'subject',
    location: '/developer-hub',
  });
}

export default function App() {
  const user = useSelector((state: any) => state.auth.user);
  return (
    <Router history={history}>
      <GoogleAnalyticsTracker/>
      <div className="app">
        <FlightNav
          user={user}
          project= {{
            id: "id",
            name: process.env.REACT_APP_NAME,
            environment: capitalize(process.env.REACT_APP_ENV),
            image: <img alt="logo" src="https://www.fintechfutures.com/files/2017/07/Flybits-1.png" />
          }}
          routes={sideBarRoutes}
          hasSidebar={false}
          actionables={
            <div className="app__nav-actionables">
              <div className="app__breadcrumbs"> </div>
            </div>
          }
          handleSignOut={() => alert('login is working in progress')}
        >
          <div className="app__main">
            <Switch>
              {/* /redirect route is the initial entry point to this application from other external websites */}
              <Route exact path="/redirect"                                           component={Pages.RedirectIndex}/>
              <Route exact path="/developer-hub"                                      component={Pages.DeveloperHubIndex}/>
              <Route exact path="/no-access"                                          component={Pages.NoAccess}/>

              <PrivateRoute exact path="/"                                            component={Pages.HomeIndex}/>
              <PrivateRoute exact path="/templated-experiences"                       component={Pages.TemplatedExperienceIndex}/>
              <PrivateRoute exact path="/templated-experiences/template-selection"    component={Pages.TemplateSelection}/>
              <PrivateRoute exact path="/templated-experiences/create"                component={Pages.TemplatedExperienceCreate}/>
              <PrivateRoute exact path="/templated-experiences/edit/:id"              component={Pages.TemplatedExperienceEdit}/>
              <PrivateRoute exact path="/templated-experiences/view/:id"              component={Pages.TemplatedExperienceView}/>

              {/* Audience Opitmization Demo */}
              <PrivateRoute exact path="/ao/templated-experiences"                    component={Pages.AOIndex}/>
              <PrivateRoute exact path="/ao/templated-experiences/template-selection" component={Pages.AOSelect}/>
              <PrivateRoute exact path="/ao/templated-experiences/create/:id"         component={Pages.AOCreate}/>
              <PrivateRoute exact path="/ao/templated-experiences/edit/:id"           component={Pages.AOEdit}/>
            </Switch>
            <SnackBar/>
          </div>
        </FlightNav>
      </div>
    </Router>
  );
}
