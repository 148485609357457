import React from 'react';
import { getIcon } from '@flybits/webapp-design-system-react';
import './incompleteCounter.scss';

export default function IncompleteCounter(props: any) {
  const label = `${props.count || 0} Incomplete Card${props.count > 1 ? 's' : ''}`
  let className = props.count > 0 ? 'incomplete-counter error' : 'incomplete-counter'
  return (
    <div
      className={className}
      onClick={() => props.onClickHandle()}
    >
      {getIcon('warning', '')}  {label}
    </div>
  )
}
