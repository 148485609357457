import React from 'react';
import { useHistory } from 'react-router-dom';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import ControlBar from 'components/ControlBar/ControlBar';
import Skeleton from 'react-loading-skeleton';
import PushIcon from "assets/icons/push.svg";
import InAppIcon from "assets/icons/in-app.svg";
import './TemplateSelection.scss';
import UseAsyncState from "hooks/useAsyncState";
import {times} from "lodash";
import {trackEvent} from "helpers/analytics.helper";

export default function TemplateSelection() {

  const history = useHistory();
  const api = new TemplatedExperienceAPI();
  const [arrData, isLoading, isError] = UseAsyncState(() => api.getTemplatedExperiences().then(r => r.data));

  const _handleSelectTemplate = (template: any) => {
    trackEvent({
      category: 'Templated Experiences',
      action: 'Select Template',
      label: template.name,
    });
    history.push(`/templated-experiences/create?template_id=${template.id}`);
  };

  const handleCancel = () => {
    history.push('/templated-experiences');
  };

  function actionIcons(template: any) {
    return template.steps[0].actions.map((action: any, idx: number) => {
      if (action.actionType === 'push') {
        return (
          <div className='template-selection__container__block__template-item__text-box__elements__element' key={`icon_${idx}`}>
            <img src={PushIcon} alt="PushIcon" className="flow-box__card-container__card__step-header__question"/>
            <div>Notification</div>
          </div>
        );
      } else if (action.actionType === 'experience') {
        return (
          <div className='template-selection__container__block__template-item__text-box__elements__element' key={`icon_${idx}`}>
            <img src={InAppIcon} alt="InAppIcon" className="flow-box__card-container__card__step-header__question"/>
            <div>In-App Content</div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  return (
    <ControlBar
      onCancel={handleCancel}
    >
      <div className="template-selection">
        <div className="template-selection__container">
          <div className="template-selection__container__block">
            <div className='template-selection__container__block__title'> Select a Customer Workflow: </div>
            <div className='template-selection__container__block__list'>
              {arrData && arrData.length ? arrData.map((template: any, idx: number) => (
                <button className='template-selection__container__block__template-item' key={`template_${idx}`} onClick={() => _handleSelectTemplate(template)}>
                  <div className='template-selection__container__block__template-item__image-box'>
                    <img src={require(`assets/images/${template.icon ? template.icon : 'image-placeholder.jpg'}`)} className='template-selection__container__block__template-item__image-box__img' alt={template.name}/>
                  </div>
                  <div className='template-selection__container__block__template-item__text-box'>
                    <div className='template-selection__container__block__template-item__text-box__title'>{template.name}</div>
                    <div className='template-selection__container__block__template-item__text-box__description'>{template.desc}</div>
                    <div className='template-selection__container__block__template-item__text-box__content'>Eligible Audience will be sent:</div>
                    <div className='template-selection__container__block__template-item__text-box__elements'>
                      {actionIcons(template)}
                    </div>
                  </div>
                </button>)
              ) : isLoading ? times(3, (i) => (
                  <Skeleton key={i} width="115%" height={200} />)
              ) : isError ? (
                <div className="template-selection__container__block__list__error">Error: {isError}</div>
              ) : (
                <div className="template-selection__container__block__list__empty">There are no templates yet</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ControlBar>
  )
}