import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Storage from "services/storage";
import moment from "moment";

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import { DropdownOptions } from 'interface/shared/dropdown.interface';
import {
  FlightButton,
  FlightTable,
  FlightOverflowMenu,
  FlightTag,
  FlightTooltip
} from '@flybits/webapp-design-system-react';
import './TemplatedExperienceIndex.scss';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import {defineStatusStyle, convertDateToTimezone} from "helpers/templated-experience.helper";
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import { trackEvent } from 'helpers/analytics.helper';

export default function TemplatedExperienceIndex() {

  const history = useHistory();

  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);

  const [maxPage, setMaxPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentRowPerPage, setCurrentRowPerPage] = useState<number>(10);

  const confirmModalProps = {
    isVisible: false,
    icon: 'warning.svg',
    title: 'Your Experience will  be Deactivated',
    content: 'You can\'t edit experiences that are active. ' +
      'Proceeding will deactivate your experience immediately.',
    primaryButton: {
      name: 'Deactivate and Edit',
      onClick: () => {},
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    }
  }
  const [modalProps, setModalProps] = useState(confirmModalProps);

  const [tableData, setTableData] = useState(undefined);
  const tableHeader = [
    {
      name: 'Name',
      key: 'name',
      isVisible: true,
      isSortable: true
    },{
      name: 'Status',
      key: 'status',
      isVisible: true,
      isSortable: true
    },{
      name: 'Schedule',
      key: 'schedule',
      isVisible: true,
      isSortable: true
    },{
      name: 'Last Modified',
      key: 'updatedAt',
      isVisible: true,
      isSortable: true
    },{
      name: 'Date Created',
      key: 'createdAt',
      isVisible: true,
      isSortable: true
    },{
      name: '',
      key: 'edit',
      isVisible: true,
      isSortable: false
    },{
      name: '',
      key: 'item',
      isVisible: false,
      isSortable: false
    },
  ];

  // Initial Load
  useEffect(() => {
    reduxTemplatedExperienceAction.fetchInstances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Re-render on state change
  useEffect(() => {

    function renderDropdown(status: string, data: TExperienceInstance) {
      let optionGroups: Array<Array<DropdownOptions>> = [[
        // {
        //   key: 2,
        //   name: ['active','scheduled'].includes(status.toLowerCase()) ? 'Deactivate' : 'Activate',
        //   disabled: status.toLowerCase() === 'draft',
        //   onClick: ['active','scheduled'].includes(status.toLowerCase()) ? () => onDeactivate(data) : () => onActivate(data) ,
        // },
        {
          key: 4,
          name: 'Duplicate',
          disabled: false,
          onClick: () => history.push(`/templated-experiences/create?instance_id=${data.id}`),
        },
        {
          key: 5,
          name: 'Delete',
          disabled: false,
          onClick: () => onDelete(data),
        },
      ]];

      return (
        <FlightOverflowMenu
          className={'templated-experiences-index__dropdown'}
          direction={'bottom'}
          isOpenRight={false}
          disabled={false}
          optionGroups={optionGroups}
        />
      )
    }

    if (reduxTemplatedExperienceState.instances?.data) {
      const pagination = reduxTemplatedExperienceState.instances.pagination;
      const maxPage = Math.ceil(pagination.totalRecords / currentRowPerPage);
      setMaxPage(maxPage >= currentPage ? maxPage : currentPage);
      const _tableData = reduxTemplatedExperienceState.instances.data.map((data: any) => {
        let startDate = data.activation?.manualActivationAt > data.schedule?.start ?
          data.activation?.manualActivationAt : data.schedule?.start;
        let endDate = data.activation?.manualDeactivationAt > data.schedule?.end ?
          data.activation?.manualDeactivationAt : data.schedule?.end;
        let timezone = data.schedule.timezone ? data.schedule.timezone : moment.tz.guess().replace(/_/g, ' ');
        let local_start_desc = null;
        let local_end_desc = null;
        let scheduleDescr = null;
        let schedule = <FlightTooltip
          className="custom-class-name"
          direction="top"
          isEnabled={true}
          description={'None set'}
          delay={0}
        >None set</FlightTooltip>;

        if(startDate) {
          let convertStart = convertDateToTimezone(startDate, timezone);
          if(convertStart.local_date+convertStart.local_time !== convertStart.tz_date+convertStart.tz_time) {
            local_start_desc = <span className="templated-experiences-index__user-timezone"><hr/> Your local time: {convertStart.local_time}, {convertStart.local_date}</span>;
          }
          schedule = <FlightTooltip
            className="templated-experiences-index__tooltip"
            direction="top"
            isEnabled={true}
            description={
              <div>
                {(data.status === 'Active' || data.status === 'Inactive') ?
                  (data.schedule?.start && data.activation?.automaticActivationAt > data.activation?.manualActivationAt) ? 'Automatically launched ' : 'Manually launched '
                  : 'Starts '}
                at {convertStart.tz_time}, {convertStart.tz_date}<br/>
                Time zone: {timezone}{local_start_desc}
              </div>
            }
            delay={0}
          >{convertStart.tz_date}</FlightTooltip>;
          if(data.status === 'Active' || data.status === 'Scheduled') {
            scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
              Starts in {moment(data.schedule.start*1000).fromNow(true)}
            </div>;
            if (data.schedule.start < moment().unix() && data.status === 'Active' ) {
              scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
                Automatically launched {moment(data.schedule.start*1000).fromNow(true)} ago
              </div>;
            }
          }
        }

        if(endDate) {
          let convertEnd = convertDateToTimezone(endDate, timezone);
          if(convertEnd.local_date+convertEnd.local_time !== convertEnd.tz_date+convertEnd.tz_time) {
            local_end_desc = <span className="templated-experiences-index__user-timezone"><hr/>Your local time: {convertEnd.local_time}, {convertEnd.local_date}</span>;
          }
          schedule = <div>{schedule} – <FlightTooltip
            className="custom-class-name"
            direction="top"
            isEnabled={true}
            description={
              <div>
                {data.status === 'Inactive' ?
                  data.schedule.end && !data.activation?.manualDeactivationAt ? 'Automatically deactivated ' :
                    !data.schedule.end && data.activation?.manualDeactivationAt ? 'Manually deactivated ' : 'Scheduled end '
                  : 'Ends '}
                at {convertEnd.tz_time}, {convertEnd.tz_date}<br/>
                Time zone: {timezone}{local_end_desc}
              </div>
            }
            delay={0}
          >{convertEnd.tz_date}</FlightTooltip></div>;
          if (data.status === 'Active' || data.schedule.start < moment().unix()) {
            let endText = 'No end date';
            if (data.schedule?.end > moment().unix()) {
              endText = 'Ends in ' + moment(data.schedule.end * 1000).fromNow(true)
            }
            if (data.schedule?.end < moment().unix()) {
              endText = 'Ended ' + moment(data.schedule.end * 1000).fromNow(true) + ' ago'
            }
            scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>{endText}</div>;
          }
        } else {
          if(startDate) {
            schedule = <div>{schedule} – <FlightTooltip
              className="custom-class-name"
              direction="top"
              isEnabled={true}
              description={`No end date`}
              delay={0}
            >No end date</FlightTooltip></div>;
          }
        }

        if(data.activation?.manualActivationAt && data.status === 'Active' && !data.schedule.end) {
          scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
            Manually launched on {moment(data.activation?.manualActivationAt * 1000).format('MMM D, YYYY')}
          </div>;
        }
        if(data.activation?.manualDeactivationAt && data.status === 'Inactive') {
          scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
            Manually deactivated on {moment(data.activation?.manualDeactivationAt * 1000).format('MMM D, YYYY')}
          </div>;
        }

        return {
          key: data.id,
          name: data.name || 'Untitled',
          status: <div><FlightTag
            type={defineStatusStyle(data?.status)}
            label={data?.status}
            className="templated-experiences-index__status"
          /></div>,
          schedule: <div>{schedule}{data.status !== 'Draft' ? scheduleDescr : ''}</div>,
          updatedAt: moment(data.updatedAt*1000).format('MMM D, YYYY'),
          createdAt: moment(data.createdAt*1000).format('MMM D, YYYY'),
          edit: renderDropdown(data?.status, data),
          item: data
        }
      });
      setTableData(_tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reduxTemplatedExperienceState.instances,
    currentPage,
    currentRowPerPage,
    history,
  ])

  // function onDeactivate(data: TExperienceInstance) {
  //   setModalProps({
  //     ...modalProps,
  //     isVisible: true,
  //     title: 'Deactivate Now',
  //     content: 'Proceeding will deactivate your experience immediately. '+
  //       'This experience will become Inactive and will not be visible to your customers. ' +
  //       'You may activate your experience from the Experiences page at any time.',
  //     primaryButton: {
  //       ...modalProps.primaryButton,
  //       name: 'Confirm',
  //       onClick: () => onDeactivateConfirm(data.id)
  //     }
  //   });
  // }

  // function onDeactivateConfirm(id: string, nextAction: string = '') {
  //   reduxTemplatedExperienceAction.deactivateTemplatedExperienceInstance(id)
  //     .then(() => {
  //       if (nextAction === 'edit') {
  //         trackEvent({
  //           category: 'Templated Experiences',
  //           action: 'Deactivate for Editing',
  //         });
  //         return history.push(`/templated-experiences/edit/${id!!}`)
  //       } else {
  //         setModalProps({
  //           ...modalProps,
  //           isVisible: false,
  //         });
  //       }
  //       trackEvent({
  //         category: 'Templated Experiences',
  //         action: 'Deactivate',
  //       });
  //     })
  //     .catch(() => alert('In development'));
  // }

  // function onActivate(data: TExperienceInstance) {
  //   let title = 'Activate Now';
  //   let message = 'This experience will become Active and visible to your customers. ' +
  //     'You may deactivate your experience from the Experiences page at any time.';
  //   if(data.schedule.start){
  //     title = 'Activate Schedule';
  //     message = 'This experience will become active and visible to your customers by scheduled start date. ' +
  //       'You may deactivate scheduled experience from the Experiences page at any time.';
  //   }
  //   setModalProps({
  //     ...modalProps,
  //     isVisible: true,
  //     title,
  //     content: message,
  //     primaryButton: {
  //       ...modalProps.primaryButton,
  //       name: 'Confirm',
  //       onClick: () => onActivateConfirm(data.id)
  //     }
  //   });
  // }

  // function onActivateConfirm(id: string) {
  //   reduxTemplatedExperienceAction.activateTemplatedExperienceInstance(id)
  //     .then(() => {
  //       setModalProps({
  //         ...modalProps,
  //         isVisible: false,
  //       });
  //       trackEvent({
  //           category: 'Templated Experiences',
  //           action: 'Activate',
  //         })
  //       }
  //     )
  //     .catch((err: any) => alert('Error: '+err));
  // }

  // function onEditClick(data: TExperienceInstance) {
  //   if (data.status === 'Active') {
  //     setModalProps({
  //       ...modalProps,
  //       isVisible: true,
  //       primaryButton: {
  //         ...modalProps.primaryButton,
  //         onClick: () => onDeactivateConfirm(data.id, 'edit')
  //       }
  //     });
  //     trackEvent({
  //       category: 'Templated Experiences',
  //       action: 'Edit',
  //     });
  //   } else {
  //     history.push(`/templated-experiences/edit/${data.id}`)
  //   }
  // }

  function onPageChange(page: number) {
    if (currentPage !== page) {
      setCurrentPage(page);
      reduxTemplatedExperienceAction.fetchInstances({limit: currentRowPerPage, offset: currentRowPerPage * (page - 1)});
    }
  }

  function onRowPerPageChange(rowPerPage: number) {
    if (currentRowPerPage !== rowPerPage) {
      const pagination = reduxTemplatedExperienceState.instances.pagination;
      setCurrentRowPerPage(rowPerPage);
      const _currentPage = (rowPerPage * currentPage) > pagination.totalRecords ? 1 : currentPage;
      setCurrentPage(_currentPage);
      reduxTemplatedExperienceAction.fetchInstances({limit: rowPerPage, offset: rowPerPage * (_currentPage - 1)});
    }
  }

  async function onBackHandle() {
    const storage = new Storage();
    let origin = await storage.getItem('origin');
    if (origin) {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Return to Experience Studio (Ember)',
      });
      window.location.href = origin as string;
    } else {
      alert('Temporary Warning: Could not find Ember Route to redirect to. Please enter this route from ember or by using the developer hub.');
      history.push('/developer-hub');
    }
  }

  function onDelete(instance: TExperienceInstance) {
    setModalProps({
      ...modalProps,
      isVisible: true,
      title: 'Delete Experience',
      content: 'Deleting is permanent and can not be undone. ' +
        'Any Active and Scheduled experiences will not be shown to customers.',
      primaryButton: {
        ...modalProps.primaryButton,
        name: 'Delete Experience',
        onClick: () => onDeleteConfirm(instance)
      }
    });
  }

  function onDeleteConfirm(instance: TExperienceInstance) {
    reduxTemplatedExperienceAction.deleteTemplatedExperienceInstance(instance, currentRowPerPage, currentPage)
      .then(() => {
        setModalProps({
          ...modalProps,
          isVisible: false,
        });
        trackEvent({
          category: 'Templated Experiences',
          action: 'Delete',
        });
      })
  }

  function onView(e: any) {
    trackEvent({
      category: 'Templated Experiences',
      action: 'View',
    });
    if (e.item.status === 'Active') {
      history.push(`/templated-experiences/view/${e.item.id}`);
    } else {
      history.push(`/templated-experiences/edit/${e.item.id}`);
    }
  }


  return (
    <div className="templated-experiences-index">
      <div className="templated-experiences-index__container">
        <div className="templated-experiences-index__header">
          <div>
            <div className="templated-experiences-index__title">Experiences 2.0</div>
            <div onClick={() => onBackHandle()} className="templated-experiences-index__subtitle">{`<`} Back to Experience Studio</div>
          </div>
          <FlightButton
            label="New Experience"
            size="large"
            className="templated-experiences-index__create-button"
            onClick={() => history.push('/templated-experiences/template-selection')}
          />
        </div>
        <div className="templated-experiences-index__main">
          <FlightTable
            tableHeaders={tableHeader}
            tableData={tableData}
            isLoading={reduxTemplatedExperienceState.isLoading}
            loadingRowNumber={10}
            hasError={!!reduxTemplatedExperienceState.errorMessage}
            errorState={<div>{reduxTemplatedExperienceState.errorMessage}</div>}
            allowRowSelect
            handleDataSelect={onView}
            paginationProps={{
              totalPageNumber: maxPage,
              currentPageNumber: currentPage,
              rowsPerPageOptions: [10, 20, 40, 60],
              currentRowsPerPage: currentRowPerPage,
              handlePageChange: onPageChange,
              handleRowsPerPageChange: onRowPerPageChange,
            }}
          />
        </div>
      </div>
      <ConfirmModal {...modalProps} />
    </div>
  );

}