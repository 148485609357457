
import React, { useState } from 'react';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import FlowBox from 'components/FlowBox/FlowBox';
import PushDetailBox from 'components/FlowBox/PushDetailBox/PushDetailBox';
import PushIcon from "assets/icons/push.svg";
import PushEditModal from 'components/Modal/EditModal/PushEditModal/PushEditModal';
import PreviewPush from 'components/PushMessage/PreviewPush/PreviewPush';

import { IForm } from 'interface/shared/form.interface';

export default function PushBox(props: any) {

  const [isPushEditModalVisible, setIsPushEditModalVisible] = useState(false);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);

  function onCancelHandle() {
    setIsPushEditModalVisible(false);
  }

  function onApplyHandle(form: IForm) {
    switch (form.type) {
      case 'basic':
        reduxTemplatedExperienceAction.setBasicPush(props, form);
        break;
      case 'weblink':
        reduxTemplatedExperienceAction.setWebLinkPush(props, form);
        break;
      default:
        break;
    }
    setIsPushEditModalVisible(false);
  }

  function body() {
    const title = props!!.actionBody?.body[0]?.payload?.title?.en;
    const message = props!!.actionBody?.body[0]?.payload?.alert?.en;
    return (
      <PreviewPush
        title={title}
        message={message}
        placement="box"
      />
    )
  }

  function showPushModal() {
    if(!props.isNotEditable) {
      setIsPushEditModalVisible(true);
    }
  }

  return (
    <React.Fragment>
      <FlowBox
        {...props}
        type="Push Notification"
        icon={PushIcon}
        body={body()}
        placeholder={body()}
        onBoxClick={showPushModal}
      />
      <PushDetailBox
        {...props}
        hasError={false}
        onBoxClick={() => setIsPushEditModalVisible(true)}
      />
      {isPushEditModalVisible && (
        <PushEditModal
          action={props}
          isVisible={isPushEditModalVisible}
          onApply={onApplyHandle}
          onCancel={onCancelHandle}
        />
      )}
    </React.Fragment>
  )
}
