
import { templates, analytics } from 'data/AOMock';
import Storage from 'services/storage';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'

// This API is for Audience Optimization Demo purposes.
// The data is being served from a static file 'data/AOMock.js' without back-end implementation.

export default class AOTemplatedExperienceAPI {

  private storage = new Storage();

  getTemplates(): Promise<any> {
    return Promise.resolve(templates);
  }

  getTemplate(id: any): Promise<any> {
    const template: any = templates.find((template) => template.id === id);
    template.steps[0].ruleStringRepresentation = '';
    template.steps[0].seedRuleStringRepresentation = '';
    return Promise.resolve(Object.assign({}, template));
  }

  async getInstances(): Promise<any> {
    const instances = await this.storage.getItem('ao:instances');
    return Promise.resolve(instances);
  }

  async getInstance(id: any): Promise<any> {
    const instances = await this.storage.getItem('ao:instances');
    const instance = instances.find((instance: any) => instance.id === id);
    return Promise.resolve(instance);
  }

  // Newly created instances will have Audience Optimization featured turned off, which prevents the user from seeing performance graphs.
  // This can be manually turned on by clicking on the row 10 times. (for Demo)
  async createInstance(instance: any): Promise<any> {
    instance.id = uuidv4().toUpperCase();
    instance.status = "Active";
    instance.createdAt = moment().format("YYYY-MM-DD HH:mm");
    instance.audienceOptimization = {
      isEnabled: false,
      audienceTarget: undefined
    };
    let instances = await this.storage.getItem('ao:instances') || [];
    instances.unshift(instance);
    await this.storage.setItem('ao:instances', instances);
    return Promise.resolve(instances);
  }

  async updateInstance(instance: any): Promise<any> {
    let instances = await this.storage.getItem('ao:instances');
    const idx = instances.findIndex((_instance: any) => _instance.id === instance.id);
    instances[idx] = instance;
    await this.storage.setItem('ao:instances', instances);
    return Promise.resolve(instances);
  }

  // Audience Optimization currently has 3 preset data types (0 ~ 2);
  async activateAO(id: string, type: number): Promise<any> {
    let instances = await this.storage.getItem('ao:instances');
    const idx = instances.findIndex((_instance: any) => _instance.id === id);
    instances[idx].audienceOptimization = {
      isEnabled: true,
      audienceTarget: undefined, // selected slider value optimization
    }
    instances[idx].analytics = analytics[type];
    await this.storage.setItem('ao:instances', instances);
    return Promise.resolve(instances);
  }

  async deleteInstance(id: string): Promise<any> {
    let instances = await this.storage.getItem('ao:instances');
    const index = instances.findIndex((instance: any) => instance.id === id);
    instances.splice(index, 1);
    await this.storage.setItem('ao:instances', instances);
    return Promise.resolve(instances);
  }

}
