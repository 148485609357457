import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { history } from 'redux/store';
import Storage from "services/storage";

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import AOTemplatedExperienceAPI from 'services/api/ao-templated-experience.api';

import FinishIcon from 'assets/icons/end.svg';
import ControlBar from 'components/ControlBar/ControlBar';
import AODemoAudienceBox from 'components/AODemo/FlowBox/AODemoAudienceBox/AODemoAudienceBox';
import AnalyticsStatCard from 'components/Analytics/AnalyticsStatCard/AnalyticsStatCard';
import AnalyticsPerformanceChart from 'components/Analytics/AnalyticsPeformanceChart/AnalyticsPeformanceChart';
import AnalyticsPerformanceOptimizedChart from 'components/Analytics/AnalyticsPerformanceOptimizedChart/AnalyticsPeformanceOptimizedChart';

import './AOEdit.scss';

interface param {
  [key: string]: string | undefined
}

export default function AOEdit() {

  const storage = new Storage();
  const dispatch = useDispatch();

  const { id }: param = useParams();
  const aoTemplatedExperienceAPI = new AOTemplatedExperienceAPI();
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);

  const confirmModalProps = {
    isVisible: false,
    title: 'Save and Activate Now',
    content: 'This experience will save as Active and become visible to your customers. ' +
      'You may deactivate your experience from the Customer Experiences page at any time',
    primaryButton: {
      name: 'Confirm',
      onClick: () => onActivateHandle(),
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    }
  }

  const [modalProps, setModalProps] = useState(confirmModalProps);
  const [selectedTab, setSelectedTab] = useState("first");

  useEffect(() => {
    aoTemplatedExperienceAPI.getInstance(id).then((instance: any) => {
      reduxTemplatedExperienceAction.setInstance({instance});
    });
    return () => {
      reduxTemplatedExperienceAction.clearInstance();
      reduxTemplatedExperienceAction.clearTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onActivateHandle() {
    //TODO: uncomment when backend is done
    alert('In development');
    // reduxTemplatedExperienceAction.updateTemplatedInstance({status: 'Active'}).then(() => {
    //   history.push('/templated-experiences');
    // });
    setModalProps({...modalProps, isVisible: false});
  }

  function onLaunchHandle() {
    aoTemplatedExperienceAPI.updateInstance(reduxTemplatedExperienceState.instance).then(() => {
      dispatch({type: 'SHOW_SNACKBAR', payload: {content: `Successfully updated experience: "${reduxTemplatedExperienceState.instance.name}"`, type: 'success'}});
      history.push('/ao/templated-experiences');
    })
  }

  function onCancelHandle() {
    storage.setItem('instance', null);
    history.push('/ao/templated-experiences');
  }

  function onNameEditHandle(name: string) {
    reduxTemplatedExperienceAction.setInstanceName(name);
  }

  function renderStep(instance: any, idx: number) {
    const props = { ...instance, key: `step_${idx}`, isRuleConfigurable: false };
    if (instance.isRoot) {
      return <AODemoAudienceBox {...props} />
    }
  }

  function renderActionTab() {
    return (
      <div className="templated-experiences-edit__main">
        {reduxTemplatedExperienceState.instance && (
          <div className="templated-experiences-edit__main__flow">
            {reduxTemplatedExperienceState.instance.steps?.map((_instance: any, idx: number) => renderStep(_instance, idx))}
            <div>
              <img className='templated-experiences-edit__finish-icon' src={FinishIcon} alt="FinishIcon"/>
            </div>
          </div>
        )}
      </div>
    )
  }

  function renderPerformanceTab() {
    const isOptimizationEnabled = reduxTemplatedExperienceState.instance?.audienceOptimization.isEnabled;
    const analytics = reduxTemplatedExperienceState.instance?.analytics;
    const totalUsers = isOptimizationEnabled ? analytics.totalUsers : 0;
    const engagements = isOptimizationEnabled ? analytics.engagements : 0;
    const engagementRate = isOptimizationEnabled ? analytics.engagementRate : 0;

    return (
      <div className="templated-experiences-edit__performance">
        <div className="templated-experiences-edit__performance__container">
          <div className="analytics-cards-container">
            {/* {reduxTemplatedExperienceState.instance?.analytics.totalUsers} */}
            <AnalyticsStatCard
              label="Total Users"
              value={totalUsers}
              tooltip="The total number of people who have qualified for the experience based one the audience restrictions that have been set."
            />
            <AnalyticsStatCard
              label="Engagements"
              value={engagements}
              tooltip="The total number of audience members who engaged."
            />
            <AnalyticsStatCard
              label="Engagement Rate"
              value={engagementRate + '%'}
              tooltip="The percentage of audience members who engaged."
            />
          </div>
          <div className="analytics-chart-container">
            {reduxTemplatedExperienceState.instance?.audienceOptimization.isEnabled ? (
              <>
                <div className="chart-item">
                  <AnalyticsPerformanceChart
                    instance={reduxTemplatedExperienceState.instance}
                  />
                </div>
                <div className="chart-item">
                  <AnalyticsPerformanceOptimizedChart
                    instance={reduxTemplatedExperienceState.instance}
                  />
                </div>
              </>
            ) : (
              <div className="no-data">
                <div className="no-data__center">
                  <h2> No data to show</h2>
                  <p> Nothing to show yet. Check back after users have been targeted. </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <ControlBar
      name={reduxTemplatedExperienceState.instance?.name || ''}
      status={reduxTemplatedExperienceState.instance?.status || 'Draft'}
      onCancel={onCancelHandle}
      onLaunch={onLaunchHandle}
      onNameEdit={onNameEditHandle}
    >
      <div className="templated-experiences-edit">
        <div className="templated-experiences-edit__header">
          <div className="tab border">
            <div className="tab__content">
              <div className="tab__content-link">
                <a
                  href="# "
                  className={selectedTab === 'first' ? 'selected' : ''}
                  onClick={() => setSelectedTab("first")}
                >
                  Actions
                </a>
                <a
                  href="# "
                  className={selectedTab === 'second' ? 'selected' : ''}
                  onClick={() => setSelectedTab("second")}
                >
                  Performance
                </a>
              </div>
              <div className={selectedTab === 'first' ? 'underline left' : 'underline right'}> </div>
            </div>
          </div>
        </div>
        {selectedTab === 'first' && renderActionTab()}
        {selectedTab === 'second' && renderPerformanceTab()}
      </div>
    </ControlBar>
  )
}
