import React from 'react';
import {FlightButton, FlightModal} from "@flybits/webapp-design-system-react";
import './ConfirmModal.scss';

export default function ConfirmModal(props: any) {

  return (
    <FlightModal
      size="medium"
      isVisible={props.isVisible}
      scrollable={true}
      toggleModalShown={() => props.cancelButton.onClick()}
      header={
        <div className="confirm-modal__header">
          {props.icon && (
            <img src={require(`assets/icons/${props.icon}`)} className="confirm-modal__header-icon" alt="modal icon"/>
          )}
          <h3> {props.title} </h3>
        </div>
      }
      content={
        <div className="confirm-modal">
          <div className="confirm-modal__content">
            {props.content}
          </div>
        </div>
      }
      footer={
        <div className="confirm-modal__footer">
          {props.cancelButton && (
            <FlightButton
              theme="secondary"
              onClick={() => props.cancelButton.onClick()}
              label={props.cancelButton.name}
            />
          )}
          {props.secondaryButton?.name && (
            <FlightButton
              type="secondary"
              onClick={() => props.secondaryButton.onClick()}
              label={props.secondaryButton.name}
            />
          )}
          {props.primaryButton && (
            <FlightButton
              onClick={() => props.primaryButton.onClick()}
              disabled={props.primaryButton.disabled}
              label={props.primaryButton.name}
            />
          )}
        </div>
      }
    />
  )
}