
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import bulletTrain from 'bullet-train-client';

// Example
//
// import useFeatureFlag from 'hooks/useFeatureFlag';
// const { flags } = useFeatureFlag();
// flags['audience_optimization_enabled] ? 1 : 0

export default function useFeatureFlag() {
  const auth = useSelector((state: any) => state.auth);
  const featureFlag = useSelector((state: any) => state.featureFlag);
  const dispatch = useDispatch();

  // List of Feature Flags.
  // A feature flag with matching name must be created on https://app.bullet-train.io/.
  const flags = [
    'audience_optimization_enabled',
  ]

  function initBulletTrain() {
    bulletTrain.identify(`${auth.user.email} - ${auth.user.id}`);
    bulletTrain.init({
      environmentID: process.env.REACT_APP_BULLET_TRAIN_ID,
        onChange: ((res: any) => {
          const flagsObj: any = {};
          flags.forEach((name: string) => { flagsObj[name] = res[name].enabled });
          dispatch({type: 'SET_FLAGS', payload: {flags: flagsObj}});
        }
      )
    });
  }

  useEffect(() => {
    if (!auth.authenticated) return;
    if (featureFlag.isInitialized) return;
    initBulletTrain();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.authenticated]);

  return {
    flags: featureFlag.flags
  };

}