import React, { useEffect } from 'react';

import { getPushPayload } from 'helpers/templated-experience.helper';

import { IForm } from 'interface/shared/form.interface';
import { Action } from 'interface/experience/experience.interface';

import { weblinkFormSchema, weblinkFormSchemaDraft } from 'validator/push-message.validator';
import useForm from 'hooks/useForm';

import {
  FlightTextInput,
  FlightTextArea,
} from '@flybits/webapp-design-system-react';

interface IProps {
  action: Action;
  isDraft: boolean;
  validateOnLoad: boolean;
  onFormChange: (formData: IForm) => void;
}

export default function WebLinkForm(props: IProps) {

  const payload = getPushPayload(props.action);

  const [data, onFieldChange, onFieldBlur, onFormSubmit] = useForm({
    formData: {
      title: payload.title.en,
      message: payload.alert.en,
      url: payload.data?.url || ''
    },
    formSchema: weblinkFormSchema,
    formSchemaDraft: weblinkFormSchemaDraft,
    isDraft: props.isDraft,
    validateOnLoad: props.validateOnLoad,
  });

  useEffect(() => {
    props.onFormChange({
      type: 'weblink',
      data,
      onFormSubmit: onFormSubmit,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <FlightTextInput
        className="create-edit-push__input"
        label="Title"
        type="text"
        iconInput=""
        hasError={data.title.hasError}
        errorMessage={data.title.error}
        placeholderText="Title"
        hasClearIcon={false}
        width="100%"
        value={data.title.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFieldChange('title', e.target.value)}
        onBlur={() => onFieldBlur('title')}
      />
      <FlightTextArea
        className="create-edit-push__input"
        label="Message"
        hasError={data.message.hasError}
        errorMessage={data.message.error}
        disabled={false}
        maxLength={170}
        width="100%"
        value={data.message.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFieldChange('message', e.target.value)}
        onBlur={() => onFieldBlur('message')}
      />
      <div className="create-edit-push__heading">
        Website Link
      </div>
      <FlightTextInput
        className="create-edit-push__input"
        label="URL"
        type="text"
        iconInput=""
        hasError={data.url.hasError}
        errorMessage={data.url.error}
        placeholderText=""
        hasClearIcon={false}
        width="100%"
        value={data.url.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFieldChange('url', e.target.value)}
        onBlur={() => onFieldBlur('url')}
      />
    </>
  )
}
