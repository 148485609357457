
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Slider from '@material-ui/core/Slider';
import { default as MaterialTooltip } from '@material-ui/core/Tooltip';

import { FlightButton, FlightSnackbar } from '@flybits/webapp-design-system-react';

import { ResponsiveContainer, CartesianGrid, AreaChart, Legend, XAxis, YAxis, Tooltip, Area } from 'recharts';
import { ReferenceArea, ReferenceDot, ReferenceLine } from 'recharts';
import './AnalyticsPerformanceOptimizedChart.scss';
import AOTemplatedExperienceAPI from 'services/api/ao-templated-experience.api';

interface IProp {
  instance: any;
}

// For now, the content of this graph is hard coded for PNC Demo.
export default function AnalyticsPerformanceOptimizedChart(props: IProp) {

  let { instance } = props;
  let { analytics } = instance;
  const aoTemplatedExperienceAPI = new AOTemplatedExperienceAPI();

  const dispatch = useDispatch();
  const [chartData, setChartData] = useState<any>([]);
  const [sliderValue, setSliderValue] = useState(instance.audienceOptimization.targetValue || 0);
  const [prevSliderValue, setPrevSliderValue] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  // Custon logic for handling tooltip
  function renderTooltip(props: any) {
    if (!props.label) return;
    const x = props.label;
    const y = chartData[x-1].x;
    return (
      <div className="custom-tooltip">
        <div className="row highlight">
          <p> Engagement Rate  </p>
          <p> {y}% </p>
        </div>
        <div className="row">
          <p> Audience Reach  </p>
          <p> {x}% </p>
        </div>
      </div>
    )
  }

  useEffect(() => {
    setChartData(generateDataPoints());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSliderChange = (event: any, newValue: any) => {
    setSliderValue(newValue);
  };

  // Let the user click on grap
  function onGraphClickHandler(graphInfo: any) {
    if (isEditing) {
      setPrevSliderValue(sliderValue);
      setSliderValue(graphInfo.activePayload[0].payload.percentage);
    }
  }

  function generateDataPoints() {
    let arr = new Array(100).fill(null).map((numb, idx) => ({
      percentage: idx + 1,
      x: analytics.optimizationGraph[idx],
    }));
    return arr;
  }

  return (
    <div className="analytics-performance-optimized-chart">
      <div className="analytics-performance-optimized-chart__main">
        <div className="top">
          <p className="headline"> Projected Optimized Audience Performance </p>
          <div>
            {(!isEditing && !instance?.audienceOptimization?.targetValue)  &&
              <FlightButton
                theme="minor"
                iconRight="baselineArrowRight"
                label={"Optimize my audience"}
                className="graph-button"
                onClick={() => {
                  if (sliderValue === 0) {
                    setSliderValue(50)
                  }
                  setIsEditing(true)
                }}
              />
            }
          </div>
        </div>
        <div className="status">
          {instance?.audienceOptimization?.targetValue &&
            <FlightSnackbar
              isVisible={true}
              isFloating={false}
              type="info"
              content={`Actively optimizing audience targeting to reach a ${chartData[instance.audienceOptimization.targetValue-1]?.x}% engagement rate`}
              isAutoDismiss={true}
              actionName="Modify"
              action={() => setIsEditing(true)}
            />
          }
        </div>
        <div className="chart">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={chartData}
              margin={{ top: 50, left: 10, bottom: 20 }}
              onClick={(graphInfo: any) => onGraphClickHandler(graphInfo)}
              >
              <CartesianGrid strokeDasharray="3 3"/>
              <Tooltip content={renderTooltip}/>
              <XAxis
                label={{ value: 'Audience Reach', position: "bottom", className: "label"}}
                dataKey="percentage" tickLine={false} ticks={[1, analytics.audienceTarget[0], 100]} tickFormatter={(e: any) => e + '%'} dx={-5} />
              <YAxis
                label={{ value: 'Engagement Rate', position: "insideLeft", angle: -90, className: "label" }}
                ticks={[analytics.audienceTarget[1], 100]}
                width={80}
                dataKey="x"
                tickFormatter={(e: any) => e + '%'}
                tickLine={false}/>
              <Legend align="left" verticalAlign="top" height={70} margin={{top: 0}}
                payload={[{ value: 'Flybits Optimized Engagement', type: 'square', color: "#5123B3", id: 'legend1' },
                          { value: 'Preferred Audience Engagement', type: 'circle', color: "#2371F1", id: 'legend2' }]}/>
              <Area type="monotone" dataKey="x" stroke="#5123B3" fillOpacity={0} className="line"/>
              {/* Current Audience Target Horizontal Guideline */}
              <ReferenceLine y={analytics.audienceTarget[1]} stroke="blue" strokeDasharray="2 2" className="reference-line"/>
              <ReferenceLine x={analytics.audienceTarget[0]} stroke="blue" strokeDasharray="2 2" className="reference-line"/>
              {/* Current Audience Target Point */}
              <ReferenceDot x={analytics.audienceTarget[0]} y={analytics.audienceTarget[1]} r={8} fill="#2371F1" fillOpacity={0.8} stroke="none"/>
              <ReferenceArea x1={1} x2={sliderValue} y1={0} y2={100} className="reference-area" stroke="black" fill="#00AB50" fillOpacity={0.1} strokeOpacity={0.1}/>
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="analytics-performance-optimized-chart__control">
        {isEditing && (
          <div className="range-slider">
            <Slider
              ValueLabelComponent={SliderLabelComponent}
              onChange={handleSliderChange}
              min={1}
              max={100}
              value={sliderValue}
              aria-label="slider"
            />
          </div>
        )}
        {isEditing && (
          <div className="button-actions">
            <FlightButton
              theme="secondary"
              label={"Cancel"}
              onClick={() => {
                setIsEditing(false);
                setSliderValue(instance.audienceOptimization.targetValue || prevSliderValue);
              }}
            />
            <FlightButton
              label={`Optimize for ${chartData[sliderValue-1].x}% Engagement Rate`}
              onClick={() => {
                setIsEditing(false);
                setPrevSliderValue(sliderValue);
                instance.audienceOptimization.targetValue = sliderValue;
                aoTemplatedExperienceAPI.updateInstance(instance).then(() => {
                  dispatch({type: 'SHOW_SNACKBAR', payload: {content: `Successfully updated audience optimization.`, type: 'success'}});
                });
              }}
            />
            {instance.audienceOptimization.targetValue > 0 && (
              <FlightButton
                type="warning"
                label={"Stop Optimized Targeting"}
                className="remove-button"
                onClick={() => {
                  instance.audienceOptimization.targetValue = undefined;
                  aoTemplatedExperienceAPI.updateInstance(instance).then(() => {
                    dispatch({type: 'SHOW_SNACKBAR', payload: {content: `Successfully removed audience optimization.`, type: 'success'}});
                  });
                  setSliderValue(0);
                  setPrevSliderValue(0);
                  setIsEditing(false);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

function SliderLabelComponent(props: any) {
  const { children, value } = props;
  return (
    <MaterialTooltip open={false} enterTouchDelay={0} placement="bottom" title={value + '% Audience Reach'}>
      {children}
    </MaterialTooltip>
  );
}
