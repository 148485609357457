import React from 'react';
import './ScheduleInline.scss';
import moment from 'moment';
import ScheduleIcon from "assets/icons/calendar-with-clock-24.svg";
import { FlightButton } from '@flybits/webapp-design-system-react';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';

interface IProps {
  experience: TExperienceInstance,
  showScheduleModal: ()=>void
}

export default function ScheduleInline(props:IProps) {

  const dateFormat = "MMM DD, YYYY hh:mmA";
  let scheduleString = <span>Add</span>;
  let start = 'No start date';
  let end = 'No end date';
  let {experience} = props;

  if(experience.schedule?.start || experience.schedule?.end) {
    if (experience.schedule.start) {
      start = moment(experience.schedule.start*1000).format(dateFormat);
    }
    if (experience.schedule.end) {
      end = moment(experience.schedule.end*1000).format(dateFormat);
    }
    scheduleString = <span>{start} - {end}</span>
  }

  function renderManuallyActivated() {
    const manualActivation = experience?.activation?.manualActivationAt;
    if(manualActivation) {
      return (
        <div className="schedule-inline__manual-activ">
          Manually launched on {moment(manualActivation * 1000).format('MMM D, YYYY')}
        </div>
      )
    }
  }

  return (
    <div className="schedule-inline">
      {renderManuallyActivated()}
      <img src={ScheduleIcon} alt="ScheduleIcon" />
      <div className={"schedule-inline__name"}>Schedule: </div>
      <FlightButton
        theme="link"
        className="schedule-inline__link"
        onClick={()=>props.showScheduleModal()}
        label={scheduleString}
      />
    </div>
  )
}