import { isEmpty } from 'lodash';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import { isPushValid } from 'validator/push-message.validator';
import { isContentValid } from 'validator/content.validator';

// For Crawl, validate the first step.
// Draft passes through all of these.
// Inactive / Schedule must be validated properly.
export function validateTemplatedExperience(templateExperience: TExperienceInstance) {
  templateExperience.steps[0].actions.forEach((action: any) => {
    action.hasError = !_actionValid(action);
  });
  return templateExperience;
}

export function getIncompleteCount(templateExperience: TExperienceInstance) {
  if (!templateExperience) return 0;
  if (isEmpty(templateExperience)) return 0;
  const step = templateExperience.steps[0];
  let count = 0;
  step?.actions.forEach((action: any) => {
    count += !_actionValid(action) ? 1 : 0;
  });
  return count;
}

function _actionValid(action: any) {
  switch (action.actionType) {
    case 'experience':
      return isContentValid(action);
    case 'push':
      return isPushValid(action);
    default:
      return;
  }
}
