
import React from 'react';

import { FlightTooltip } from '@flybits/webapp-design-system-react';
import QuestionIcon from "assets/icons/question.svg";
import './AnalyticsStatCard.scss';

interface IProp {
  tooltip?: string;
  label: string;
  value: string | number;
}

export default function AnalyticsStatCard(props: IProp) {
  return (
    <div className="analytics-stat-card">
      <div className="analytics-stat-card__top">
        <p className="label"> {props.label} </p>
        {props.tooltip && (
          <FlightTooltip
            className="tooltip"
            direction="top"
            isEnabled={true}
            description={props.tooltip}
            delay={0}
          >
            <img src={QuestionIcon} alt="QuestionIcon"/>
          </FlightTooltip>
        )}
      </div>
      <div className="analytics-stat-card__bottom">
        <p className="value"> {props.value} </p>
      </div>
    </div>
  )
}

