import { TExperienceActionType, TExperienceState } from 'redux/templated-experience/templated-experience.type';
import {updateTemplatedInstanceStatus} from "helpers/templated-experience.helper";

let initialState: TExperienceState = {
  instances: {
    data: [],
    pagination: {
      limit: 0,
      offset: 0,
      sortBy: '',
      sortOrder: '',
      totalRecords: 0,
    }
  }, // instances in the list view
  instance: null,
  template: null, // individual template for /create/:id route
  isLoading: true,
  errorMessage: '',
  isModified: false,
}

export default (state: TExperienceState = initialState, action: any) => {
  switch (action.type) {
    case TExperienceActionType.SET_INSTANCES:
      return {
        ...state,
        instances: Object.assign({}, action.payload.instances),
      }
    case TExperienceActionType.SET_TEMPLATE:
      return {
        ...state,
        template: Object.assign({}, action.payload.template),
      }
    case TExperienceActionType.SET_INSTANCE:
      return {
        ...state,
        instance: Object.assign({}, action.payload.instance),
      }
    case TExperienceActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.flag
      }
    case TExperienceActionType.SET_MODIFIED:
      return {
        ...state,
        isModified: action.payload,
      }
    case TExperienceActionType.UPDATE_INSTANCES:
      const updatedInstances = updateTemplatedInstanceStatus(state.instances, action.payload.id, action.payload.status);
      return {
        ...state,
        instances: {...state.instances, data: [...updatedInstances]},
      }
    default:
      return {
        ...state
      };
  }
};