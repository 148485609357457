import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { times } from 'lodash';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import { history } from 'redux/store';
import Storage from "services/storage";
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import {trackEvent} from "helpers/analytics.helper";

import Skeleton from 'react-loading-skeleton';
import FinishIcon from 'assets/icons/end.svg';
import ControlBar from 'components/ControlBar/ControlBar';
import AudienceBox from 'components/FlowBox/AudienceBox/AudienceBox';
import IncompleteCounter from 'components/IncompleteCounter/IncompleteCounter';
import './TemplatedExperienceEdit.scss';
import ScheduleInline from "components/ScheduleInline/ScheduleInline";
import ScheduleEditModal, {scheduleDataType} from "components/Modal/EditModal/ScheduleEditModal/ScheduleEditModal";
import {transformScheduleData, getWarningOnLaunchScheduled} from "helpers/templated-experience.helper";

interface param {
  [key: string]: string | undefined
}

export default function TemplatedExperienceEdit() {
  const storage = new Storage();
  const { id }: param = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const confirmModalProps = {
    isVisible: false,
    title: 'Save and Activate Now',
    content: 'This experience will save as Active and become visible to your customers. ' +
      'You may deactivate your experience at any time but can not revert it to Draft.',
    primaryButton: {
      name: 'Confirm',
      onClick: () => onActivateHandle(),
      disabled: false,
    },
    secondaryButton: reduxTemplatedExperienceState.status === 'Draft' ? {
      name: 'Save as Draft',
      onClick: () => onDraftHandle(),
      disabled: false,
    } : undefined,
    cancelButton: {
      name: 'Cancel',
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    }
  }
  const [modalProps, setModalProps] = useState<any>(confirmModalProps);

  const scheduleModalProps = {
    isVisible: false,
    title: 'Schedule Activation',
    instance: {},
    primaryButton: {
      name: 'Confirm',
      onClick: (scheduleData: scheduleDataType) => setScheduleData(scheduleData),
      disabled: false,
      loading: false,
    },
    cancelButton: {
      name: '',
      onClick: () => onScheduleCancel(),
    }
  }
  const [scheduleProps, setScheduleProps] = useState<any>(scheduleModalProps);

  useEffect(() => {
    reduxTemplatedExperienceAction.fetchInstance(id).then(() => setIsLoaded(true));
    return () => reduxTemplatedExperienceAction.clearInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onActivateHandle() {
    reduxTemplatedExperienceAction.updateTemplatedInstance({status: 'Active'}).then(() => {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Edit and Activate',
      });
      history.push('/templated-experiences');
    });
    setModalProps({...modalProps, isVisible: false});
  }

  function onLaunchHandle() {
    const schedule = reduxTemplatedExperienceState.instance?.schedule;
    const {startDate, endDate, newTitle, newMessage} = getWarningOnLaunchScheduled(schedule);
    let title = modalProps.title;
    let content = modalProps.content;
    let isVisible = true;
    let primaryButton = modalProps.primaryButton;
    if(schedule.start!!) {
      title = newTitle;
      content = (
        <div>
          <div>{newMessage}</div>
          <div className="templated-experiences-edit__warning-schedule">
            <div><strong>Start date:</strong> {startDate}</div>
            <div><strong>End date:</strong> {endDate}</div>
          </div>
        </div>
      );
      isVisible = true;
      primaryButton = {
        ...modalProps.primaryButton,
        onClick: () => {
          reduxTemplatedExperienceAction.updateTemplatedInstance({status: 'Scheduled'}).then(() => {
            trackEvent({
              category: 'Templated Experiences',
              action: 'Edit and Schedule',
            });
            history.push('/templated-experiences');
          });
          setModalProps({...modalProps, isVisible: false});
        }
      }
    }

    setModalProps({
      ...modalProps,
      title,
      content,
      isVisible,
      primaryButton,
    });
  }

  function onSaveHandle() {
    let status = reduxTemplatedExperienceState.instance?.status;
    const schedule = reduxTemplatedExperienceState.instance?.schedule;
    let title = modalProps.title;
    let content = modalProps.content;
    let isVisible = false;
    let primaryButton = modalProps.primaryButton;

    if (status === 'Scheduled' && !schedule.start && !schedule.end) {
      title = 'Launch Immediately';
      content = 'This experience does not have a schedule associated with it. ' +
        'As a result, it will launch immediately and be visible to your customers. ' +
        'If you wish to add a schedule, click on "Cancel" and add a schedule.';
      isVisible = true;
      status = 'Active';
      primaryButton = {
        ...modalProps.primaryButton,
        onClick: () => {
          reduxTemplatedExperienceAction.updateTemplatedInstance({status})
            .then(() => {
              setModalProps({
                ...modalProps,
                isVisible: false,
              });
              trackEvent({
                category: 'Templated Experiences',
                action: 'Remove schedule on Scheduled Experience and Activate',
              })
              history.push('/templated-experiences');
            })
            .catch((err: any) => alert('Error: '+err));
        }
      }
    }

    if(isVisible) {
      setModalProps({
        ...modalProps,
        title,
        content,
        isVisible,
        primaryButton,
      });
    } else {
      reduxTemplatedExperienceAction.updateTemplatedInstance({status}).then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Edit and Save',
        });
        history.push('/templated-experiences');
      });
      setModalProps({...modalProps, isVisible: false});
    }
  }

  function onCancelHandle() {
    storage.setItem('instance', null);
    history.push('/templated-experiences');
  }

  function onDraftHandle() {
    reduxTemplatedExperienceAction.updateTemplatedInstance({status: 'Draft'}).then(() => {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Edit and Save as Draft',
      });
      history.push('/templated-experiences');
    });
  }

  function onNameEditHandle(name: string) {
    reduxTemplatedExperienceAction.setInstanceName(name);
  }

  function renderStep(instance: any, idx: number) {
    const props = { ...instance, key: `step_${idx}` };
    if (instance.isRoot) {
      return <AudienceBox {...props} />
    }
  }

  function onScheduleHandle() {
    setScheduleProps({
      ...scheduleProps,
      isVisible: true
    });
  }

  function setScheduleData(scheduleData:scheduleDataType) {
    reduxTemplatedExperienceAction.setSchedule(transformScheduleData(scheduleData));
    setScheduleProps({
      ...scheduleProps,
      isVisible: false,
    });
  }

  function onScheduleCancel() {
    setScheduleProps(scheduleModalProps);
  }

  function onDeactivate() {
    setModalProps({
      ...modalProps,
      isVisible: true,
      title: 'Your Experience will be Deactivated',
      content: 'Proceeding will deactivate your experience immediately ' +
        'and it will no longer be visible to your customers per the schedule.',
      primaryButton: {
        ...modalProps.primaryButton,
        name: 'Confirm',
        onClick: () => onDeactivateConfirm()
      }
    });
  }

  function onDeactivateConfirm() {
    reduxTemplatedExperienceAction.deactivateTemplatedExperienceInstance(reduxTemplatedExperienceState.instance?.id)
      .then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Unschedule for Editing',
        });
        reduxTemplatedExperienceState.instance.status = 'Inactive';
        reduxTemplatedExperienceAction.setInstance({instance: reduxTemplatedExperienceState.instance});
        setModalProps({
          ...modalProps,
          isVisible: false,
        });
      })
      .catch(() => alert('In development'));
  }

  return (
    <>
      {reduxTemplatedExperienceState.instance && (
      <ControlBar
        name={reduxTemplatedExperienceState.instance?.name || ''}
        status={reduxTemplatedExperienceState.instance?.status || 'Draft'}
        onDeactivate={reduxTemplatedExperienceState.instance?.status === 'Scheduled' ? onDeactivate : undefined}
        onCancel={onCancelHandle}
        onSave={onSaveHandle}
        onLaunch={reduxTemplatedExperienceState.instance?.status !== 'Scheduled' ? onLaunchHandle : undefined}
        onLaunchDisabled={reduxTemplatedExperienceState.instance?.incompleteCount > 0}
        onNameEdit={onNameEditHandle}
      >
        <div className="templated-experiences-edit">
          <div className="templated-experiences-edit__main">
            {reduxTemplatedExperienceState.isLoading ? (
              <div className="templated-experiences-edit__main__progress">
                {times(3, (i) => (
                  <div key={i} style={{ marginRight: 100}}>
                    <Skeleton width={300} height={300} />
                  </div>
                ))}
              </div>
            ) : isLoaded && reduxTemplatedExperienceState.instance ? (
            <>
              <ScheduleInline showScheduleModal={onScheduleHandle} experience={reduxTemplatedExperienceState.instance}/>
              {/* Incomplete Counter is visible only for Draft status */}
              {reduxTemplatedExperienceState.instance.status === 'Draft' && (
                <IncompleteCounter
                  count={reduxTemplatedExperienceState.instance.incompleteCount}
                  onClickHandle={() => reduxTemplatedExperienceAction.showError('instance')}
                />
              )}
              <div className="templated-experiences-edit__main__flow">
                {reduxTemplatedExperienceState.instance.steps?.map((_instance: any, idx: number) => renderStep(_instance, idx))}
                <div>
                  <img className='templated-experiences-edit__finish-icon' src={FinishIcon} alt="FinishIcon"/>
                </div>
              </div>
            </>
          ) : isLoaded && reduxTemplatedExperienceState.errorMessage ? (
            <div className="templated-experiences-edit__main__error">Error: {reduxTemplatedExperienceState.errorMsg}</div>
          ) : isLoaded && (
            <div className="templated-experiences-edit__main__empty"> There are no template </div>
          )}
          </div>
          <ConfirmModal {...modalProps} />
          <ScheduleEditModal {...scheduleProps} schedule={reduxTemplatedExperienceState.instance.schedule} />
        </div>
      </ControlBar>
      )}
    </>
  )
}
