
import { isNumber } from 'lodash';

export const conditionOptions = [
  {
    key: 'And',
    name: 'All',
    detail: 'of the following requirements must be met to qualify for the experience.'
  }, {
    key: 'Or',
    name: 'Any',
    detail: 'of the following requirements must be met to qualify for the experience.'
  }
];

export const operatorOptions = {
  boolean: [
    {key: 'boolEq', name: 'is equal to'},
    {key: 'notEq', name: 'is not equal to'}
  ],
  number: [
    {key: 'eq', name: 'is equal to'},
    {key: 'neq', name: 'is not'},
    {key: 'ls', name: 'is less than'},
    {key: 'lseq', name: 'is less than or equal to'},
    {key: 'gr', name: 'is greater than'},
    {key: 'greq', name: 'is greater than or equal to'}
  ],
  string: [
    {key: 'stringFoldEq', name: 'is'},
    {key: 'stringFoldNeq', name: 'is not'},
    {key: 'stringFoldContains', name: 'contains'},
  ]
}

export const booleanOptions = [
  {key: 'true', name: 'true'},
  {key: 'false', name: 'false'},
]

export function getOperatorOptions(valueType: string) {
  switch (valueType) {
    case 'bool':
      return operatorOptions['boolean'];
    case 'float':
    case 'int':
      return operatorOptions['number'];
    case 'string':
      return operatorOptions['string'];
    default:
      break;
  }
}

export function getOperatorByKey(key: string) {
  const operators = [
    ...operatorOptions.boolean,
    ...operatorOptions.number,
    ...operatorOptions.string,
  ];
  return operators.find((operator: any) => operator.key === key);
}

// convert audience row in the AudienceEdit to string
export function toString(audience: any) {
  const { plugin, operator, attribute } = audience;
  if (!operator) return;
  if (!isNumber(attribute) && !attribute) return;

  switch (plugin.valueType) {
    case 'bool':
      return `${operator.key}(${plugin.stringRep},${attribute.key})`;
    case 'string':
      return `${operator.key}(${plugin.stringRep},'${attribute}')`;
    case 'int':
    case 'float':
      return `${operator.key}(${plugin.stringRep},${attribute})`;
    default:
      break;
  }
}
