
import React, { useState } from 'react';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import FlowBox from 'components/FlowBox/FlowBox';
import ContentIcon from "assets/icons/in-app.svg";
import ContentListModal from 'components/Modal/ListModal/ContentListModal/ContentListModal';
import PreviewContent from 'components/Content/PreviewContent/PreviewContent';

export default function ContentBox(props: any) {

  const [isContentModalVisible, setIsContentModalVisible] = useState(false);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);

  function body() {
    return (
      <PreviewContent
        content={props!!.actionBody.localizations.en.name}
        placement="box"
      />
    )
  }

  function onApplyHandle(content: any) {
    reduxTemplatedExperienceAction.setContent(props, content);
    setIsContentModalVisible(false);
  }

  function showContentModal() {
    if(!props.isNotEditable) {
      setIsContentModalVisible(true);
    }
  }

  return (
    <React.Fragment>
      <FlowBox
        {...props}
        type="Send In-app Content"
        icon={ContentIcon}
        body={body()}
        placeholder={body()}
        onBoxClick={showContentModal}
      />
      {isContentModalVisible && (
        <ContentListModal
          isVisible={isContentModalVisible}
          selectedId={props.actionBody.contentIds[0]}
          onCancel={() => setIsContentModalVisible(false)}
          onApply={(content: any) => onApplyHandle(content)}
        />
      )}
    </React.Fragment>
  )
}
