import { v4 as uuidv4 } from 'uuid';
import { TExperience, TExperienceInstance } from "interface/templated-experience/templated-experience.interface";
import momentTZ from "moment-timezone";
import moment from 'moment';

import {scheduleDataType} from "components/Modal/EditModal/ScheduleEditModal/ScheduleEditModal";
import {ExperienceSchedule} from "../interface/experience/experience.interface";

/**
 * Returns the index of steps array, where actionID is present in actions array of this step.
 * @param template template object in current reducer
 * @param actionID actionID of the card/box
 * @return [parent, child] returns parent and child, where parent is the index in steps array,
 * and child is the index in actionBody array.
 */
export function findActionParent(template: TExperience, actionID: string): [number, number]{
  for (var s = 0; s < template.steps.length; s++) {
    let currentStep = template.steps[s];
    for (var a = 0; a < currentStep.actions.length; a++) {
      if (currentStep.actions[a].id === actionID) {
        return [s, a];
      }
    }
  }
  return [0, 0];
}

// Convert the JSON response received from GET to a compatible object ready for POST request
export function convertToInstance(template: TExperience): TExperienceInstance {
  delete template.updatedAt;
  delete template.createdAt;
  return {
    ...template,
    name: '',
    status: '',
    schedule: {
      start: 0,
      end: 0,
      timezone: '',
    },
    incompleteCount: 0,
    showError: false,
  }
}

/**
 * Generates UUID for a single step templated experience creation.
 * This function should be updated to meet the critera for nested steps in the future.
 * @param template
 */
export function generateUUID(template: TExperienceInstance): TExperienceInstance {
  template.rootStepID = uuidv4().toUpperCase();
  const rootStep = template.steps[0];
  rootStep.id = template.rootStepID;
  for (var i = 0; i < rootStep.actions.length; i++) {
    rootStep.actions[i].id = uuidv4().toUpperCase();
  }
  return template;
}

/**
 *
 * @param action The push action step
 * @returns the payload object that is located inside the actionBody of step.
 */
export function getPushPayload(action?: any) {
  return action?.actionBody?.body[0]?.payload;
}

/**
 * Returns the push type for the PushBox component
 * @returns the push type string.
 * @param action
 */
export function getPushType(action: any) {
  const payload = getPushPayload(action);
  if (payload.data?.url !== undefined) {
    return 'weblink';
  } else {
    return 'basic';
  }
}

/**
 * Returns the style for the experience label, based on the status property
 * @returns the style name
 * @param status
 */
export function defineStatusStyle(status: string) {
  if (status === 'Active') return 'success';
  if (status === 'Inactive') return 'error';
  if (status === 'Draft') return 'default';
  if (status === 'Scheduled') return 'info';
  return 'default';
}

/**
 * Returns updated instance with a new status
 * @returns updated instance object
 * @param instances
 * @param id
 * @param status
 */
export function updateTemplatedInstanceStatus(instances: any, id: string, status: string) {
  return instances.data.map((instance: TExperienceInstance) => {
    if (instance.id === id) {
      instance.status = status;
    }
    return instance;
  })
}

/**
 * Returns updated instance with Untitled content name if the content name is empty
 * @returns updated instance object
 * @param instance
 * @param name
 */
export function updateEmptyContentName(instance: TExperienceInstance, name: string = 'Untitled') {
  if(instance.steps.length) {
    instance.steps.map(step => {
      if(step.actions.length) {
        step.actions.map(action => {
          if(action.actionType === 'experience') {
            if(action.actionBody?.localizations?.en?.name === ''){
              return action.actionBody.localizations.en.name = name;
            }
          }
          return action;
        })
      }
      return step;
    })
  }
  return instance;
}


/**
 * Returns the list of timezones from the 'moment' library
 * @returns array
 */
export function getTimezoneList(searchZone: string = '') {
  let timezones = momentTZ.tz.names().map((timezone:any, idx: number) => {
    return {
      key: idx+1,
      name: timezone.replace(/_/g, ' ')+' (UTC '+momentTZ.tz(timezone).format('Z')+')',
      offset: parseInt(momentTZ.tz(timezone).format('Z')),
    };
  });

  if(searchZone) {
    timezones = timezones.filter((timezone) => {
      return timezone.name.toLowerCase().search(
        searchZone.toLowerCase().replace(/[_)(+]/g, ' ')
      ) >= 0;
    })
  }

  return timezones.sort((a:any, b:any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
}


/**
 * Returns timezone name without UTC data
 * @returns string
 */
export function cleanupTimezone(timezone: string) {
  const start = timezone?.indexOf(' (UTC');
  if(start > -1) {
    timezone = timezone?.slice(0, start);
  }
  timezone = timezone?.replace(/ /, '_');
  return timezone;
}


/**
 * Returns date and time, converted to timezone + user's local time
 * @returns object
 */
export function convertDateToTimezone(date: number, timezone: string) {
  return {
    tz_date: momentTZ.tz(date*1000, cleanupTimezone(timezone)).format('MMM D, YYYY'),
    tz_time: momentTZ.tz(date*1000, cleanupTimezone(timezone)).format('hh:mmA'),
    local_date: moment(date*1000).format('MMM D, YYYY'),
    local_time: moment(date*1000).format('hh:mmA')
  };
}


/**
 * Returns transformed schedule data, that match the backend requirements
 * @returns object
 */
export function transformScheduleData(scheduleData: scheduleDataType) {
  const cleanTimezone = cleanupTimezone(scheduleData.timezone.name);
  const start = moment(scheduleData.start || 0).format("YYYY-MM-DD HH:mm");
  const end = moment(scheduleData.end || 0).format("YYYY-MM-DD HH:mm");

  const convertedStartTime = momentTZ.tz(start, cleanTimezone).unix();
  const convertedEndTime = momentTZ.tz(end, cleanTimezone).unix();

  let schedule:ExperienceSchedule = {
    start: scheduleData.start ? convertedStartTime : 0,
    end: scheduleData.end ? convertedEndTime : 0,
    timezone: scheduleData.timezone.name,
  };

  return schedule;
}


/**
 * Returns difference between two dates
 * @returns string
 */
const PERIODS = ["months", "weeks", "days", "hours", "minutes", "seconds"];
interface funcType {
  start:Date,
  end:Date,
  period: string
}
export function getDuration(start:Date, end:Date, period:any = "days"):funcType|string {
  const validForUnit = moment(end).diff(start, period);
  if (validForUnit > 1 || period === "seconds") {
    return `${validForUnit} ${period}`;
  }
  return getDuration(start, end, PERIODS[PERIODS.indexOf(period) + 1]);
}


/**
 * Returns the object with warning variables on launching scheduled experience
 * @returns object
 */
export function getWarningOnLaunchScheduled(schedule: TExperienceInstance['schedule']) {
  const startDate = moment((schedule.start || 0) *1000).format('MMM DD, YYYY [at] h:mmA');
  const endDate = schedule.end ? moment((schedule.end || 0)*1000).format('MMM DD, YYYY [at] h:mmA') : 'No end date';
  let newTitle = '';
  let newMessage = '';
  if(schedule.start!!) {
    newTitle = 'Launch on Date';
    newMessage = 'This experience has a schedule associated with it. '+
        'As a result, it will launch with the following dates:';
  }
  return ({startDate, endDate, newTitle, newMessage,})
}