import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export default function GoogleAnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'development') {
      document.title = 'Flybits [Dev] - Templated Experience'
    }
    ReactGA.initialize(process.env.REACT_APP_GA_ID!!);
    ReactGA.pageview(location.pathname);
  },[location.pathname]);

  return (
    <React.Fragment>
    </React.Fragment>
  )
}
