import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { times } from 'lodash';

import qs from 'qs';
import {TExperienceInstance} from 'interface/templated-experience/templated-experience.interface';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import { history } from 'redux/store';
import Storage from "services/storage";
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import {trackEvent} from "helpers/analytics.helper";
import ScheduleEditModal, { scheduleDataType } from 'components/Modal/EditModal/ScheduleEditModal/ScheduleEditModal';
import {getWarningOnLaunchScheduled, transformScheduleData} from 'helpers/templated-experience.helper';

import Skeleton from 'react-loading-skeleton';
import FinishIcon from 'assets/icons/end.svg';
import ControlBar from 'components/ControlBar/ControlBar';
import IncompleteCounter from 'components/IncompleteCounter/IncompleteCounter';
import AudienceBox from 'components/FlowBox/AudienceBox/AudienceBox';

import './TemplatedExperienceCreate.scss';
import ScheduleInline from 'components/ScheduleInline/ScheduleInline';


export default function TemplatedExperienceCreate() {
  const storage = new Storage();
  const location = useLocation();
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const scheduleModalProps = {
    isVisible: false,
    title: 'Schedule Activation',
    instance: {},
    primaryButton: {
      name: 'Confirm',
      onClick: (scheduleData: scheduleDataType) => setScheduleData(scheduleData),
      disabled: false,
      loading: false,
    },
    cancelButton: {
      name: '',
      onClick: () => onScheduleCancel(),
    }
  }
  const [scheduleProps, setScheduleProps] = useState(scheduleModalProps);

  const confirmModalProps = {
    isVisible: false,
    icon: '',
    title: 'Save and Activate Now',
    content: 'This experience will save as Active and become visible to your customers. ' +
      'You may deactivate your experience from the Customer Experiences page at any time but can not revert it to Draft.',
    primaryButton: {
      name: 'Confirm',
      onClick: () => onActivateHandle(),
      disabled: false,
    },
    secondaryButton: {
      name: 'Save as Draft',
      onClick: () => onDraftHandle(),
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    }
  }
  const [modalProps, setModalProps] = useState(confirmModalProps);

  // supports two different query param for loading template information
  // create?template_id=xxx  <-- used for creation of instance from template
  // create?instance_id=xxx  <-- used for duplication of existing instance
  useEffect(() => {
    if (query.template_id) { // creation from template
      reduxTemplatedExperienceAction.fetchTemplate(query.template_id).then(() => {
        setIsLoaded(true)
      });
    } else if (query.instance_id) { // duplication of existing instance
      reduxTemplatedExperienceAction.fetchInstance(query.instance_id).then((instance: TExperienceInstance) => {
        reduxTemplatedExperienceAction.setTemplate({template: Object.assign(instance, {
          name: `${instance.name || 'Untitled'} - Copy`,
          activation: {
            automaticActivationAt: 0,
            automaticDeactivationAt: 0,
            manualActivationAt: 0,
            manualDeactivationAt: 0
          }
        })});
        setIsLoaded(true)
      });
    }
    return () => {
      reduxTemplatedExperienceAction.clearTemplate();
    }
  }, [query.template_id, query.instance_id, reduxTemplatedExperienceAction]);

  function onActivateHandle() {
    reduxTemplatedExperienceAction.createTemplatedInstance({status: 'Active'}).then(() => {
      history.push('/templated-experiences');
    });
    setModalProps({...modalProps, isVisible: false});
  }

  function onLaunchHandle() {
    const schedule = reduxTemplatedExperienceState.template?.schedule;
    const {startDate, endDate, newTitle, newMessage} = getWarningOnLaunchScheduled(schedule);
    let title = modalProps.title;
    let content:any = modalProps.content;
    if(schedule.start) {
      title = newTitle;
      content = (
        <div>
          <div>{newMessage}</div>
          <div className="templated-experiences-edit__warning-schedule">
            <div><strong>Start date:</strong> {startDate}</div>
            <div><strong>End date:</strong> {endDate}</div>
          </div>
        </div>
      );
    }
    setModalProps({...modalProps, title, content, isVisible: true});
  }

  function onCancelHandle() {
    if(reduxTemplatedExperienceState.isModified) {
      setModalProps({
        ...modalProps,
        isVisible: true,
        icon: 'warning.svg',
        title: 'You have unsaved changes',
        content: 'If you leave this page, all unsaved changes made will be lost.',
        primaryButton: {
          name: 'Confirm',
          onClick: () => {
            storage.setItem('template', null);
            reduxTemplatedExperienceAction.setModified(false);
            history.push('/templated-experiences/template-selection');
          },
          disabled: false,
        },
        secondaryButton: {
          name: '',
          onClick: () => {},
          disabled: true,
        },
        cancelButton: {
          name: 'Cancel',
          onClick: () => setModalProps({...modalProps, isVisible: false}),
        }
      });
    } else {
      storage.setItem('template', null);
      history.push('/templated-experiences/template-selection');
    }
  }

  function onDraftHandle() {
    reduxTemplatedExperienceAction.createTemplatedInstance({status: 'Draft'}).then(() => {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Create and Save as Draft',
      });
      history.push('/templated-experiences');
    });
  }

  function onNameEditHandle(name: string) {
    reduxTemplatedExperienceAction.setName(name);
    reduxTemplatedExperienceAction.setModified(true);
  }

  function onScheduleHandle() {
    setScheduleProps({
      ...scheduleProps,
      isVisible: true
    });
  }

  function setScheduleData(scheduleData:scheduleDataType) {
    reduxTemplatedExperienceAction.setSchedule(transformScheduleData(scheduleData));
    setScheduleProps({
      ...scheduleProps,
      isVisible: false,
    });
  }

  function onScheduleCancel() {
    setScheduleProps(scheduleModalProps);
  }

  function renderStep(template: any, idx: number) {
    const props = { ...template, key: `step_${idx}` };
    if (template.isRoot) {
      return <AudienceBox {...props} />
    }
  }

  return (
    <>
      {reduxTemplatedExperienceState.template && (
        <ControlBar
          name={reduxTemplatedExperienceState.template.name || ''}
          status="Draft"
          onCancel={onCancelHandle}
          onDraft={()=>onDraftHandle()}
          onLaunch={onLaunchHandle}
          onLaunchDisabled={reduxTemplatedExperienceState.template.incompleteCount > 0}
          onNameEdit={onNameEditHandle}
        >
          {isLoaded && (
            <div className="templated-experiences-create">
              <div className="templated-experiences-create__main">
                {reduxTemplatedExperienceState.isLoading ? (
                  <div className="templated-experiences-create__main__progress">
                    {times(3, (i) => (
                      <div key={i} style={{ marginRight: 100}}>
                        <Skeleton width={300} height={300} />
                      </div>
                    ))}
                  </div>
                  ) : reduxTemplatedExperienceState.template ? (
                    <>
                      <ScheduleInline showScheduleModal={onScheduleHandle} experience={reduxTemplatedExperienceState.template}/>
                      <IncompleteCounter
                        count={reduxTemplatedExperienceState.template.incompleteCount}
                        onClickHandle={() => reduxTemplatedExperienceAction.showError('template')}
                        />
                      <div className="templated-experiences-create__main__flow">
                        {reduxTemplatedExperienceState.template?.steps?.length && (
                          reduxTemplatedExperienceState.template.steps.map((_template: any, idx: number) => renderStep(_template, idx))
                        )}
                        <div>
                          <img className='templated-experiences-create__finish-icon' src={FinishIcon} alt="FinishIcon"/>
                        </div>
                      </div>
                    </>
                  ) : isLoaded && reduxTemplatedExperienceState.template ? (
                    <div className="templated-experiences-create__main__flow">
                      {reduxTemplatedExperienceState.template.steps?.map((_template: any, idx: number) => renderStep(_template, idx))}
                      <div>
                        <img className='templated-experiences-create__finish-icon' src={FinishIcon} alt="FinishIcon"/>
                      </div>
                    </div>
                  ) : isLoaded && reduxTemplatedExperienceState.errorMessage ? (
                    <div className="templated-experiences-create__main__error">Error: {reduxTemplatedExperienceState.errorMsg}</div>
                  ) : isLoaded && (
                    <div className="templated-experiences-create__main__empty"> There are no template </div>
                  )
                }
              </div>
              <ConfirmModal {...modalProps} />
              <ScheduleEditModal {...scheduleProps} schedule={reduxTemplatedExperienceState.template.schedule} />
            </div>
          )}
        </ControlBar>
      )}
    </>
  )
}
