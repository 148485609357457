
import React from 'react';
import ContentAPI from 'services/api/content.api';

import { Content } from 'interface/content/content.interface';
import ListModal from 'components/Modal/ListModal/ListModal';

interface IProps {
  isVisible: boolean;
  selectedId: string;
  onCancel: () => void;
  onApply: (coontent: Content) => void;
}

export default function ContentListSelectModal(props: IProps) {
  const contentAPI = new ContentAPI();
  return (
    <ListModal
      {...props}
      icon="in-app.svg"
      title="Content Title"
      fetchData={() => contentAPI.getContents().then((r: any) => r.data)}
      dataKey="localizations.en.name"
    />
  )
}
