
import BaseAPI from './base.api';

import { PaginationResponse } from 'interface/shared/api.interface';
import { Content } from 'interface/content/content.interface';

export default class ContentAPI extends BaseAPI {
  private ROUTES = {
    all: '/kernel/content/instances'
  }

  getContents(opts = {}): Promise<PaginationResponse<Content>> {
    let params = {
      limit: 0,
      offset: 0,
      sortby: 'createdAt',
      sortorder: 'desc'
    };
    params = Object.assign(params, opts);
    return this.GET(this.ROUTES.all, params);
  }
}