import validator from 'validator'; // https://github.com/validatorjs/validator.js
import { getPushType, getPushPayload } from 'helpers/templated-experience.helper';

// Perform validation based on the schema above.
export function isPushValid(action: any) {
  const pushType = getPushType(action);
  switch (pushType) {
    case 'weblink':
      return validateWeblinkForm(action);
    case 'basic':
      return validateBasicForm(action);
    default:
      return false;
  }
}

export function validateBasicForm(action: any) {
  const payload = getPushPayload(action);
  const data = {
    title: {value: payload.title.en},
    message: {value: payload.alert.en}
  }
  return isValid(basicFormSchema(data));
}

export function validateWeblinkForm(action: any) {
  const payload = getPushPayload(action);
  const data = {
    title: {value: payload.title.en},
    message: {value: payload.title.en},
    url: {value: payload.data.url}
  }
  return isValid(weblinkFormSchema(data));
}

export function basicFormSchema(data: any) {
  return {
    title: () => [
      [validator.isLength(data.title.value, {min: 1, max: 170}), 'Required Field']
    ],
    message: () => [
      [validator.isLength(data.message.value, {min: 1, max: 170}), 'Required Field']
    ]
  }
}

export function basicFormSchemaDraft(data: any) {
  return {
    title: () => [
      [validator.isLength(data.title.value, {min: 0, max: 170}), 'Cannot exceed 170 characters']
    ],
    message: () => [
      [validator.isLength(data.message.value, {min: 0, max: 170}), 'Cannot exceed 170 characters']
    ]
  }
}

export function weblinkFormSchema(data: any) {
  return {
    title: () => [
      [validator.isLength(data.title.value, {min: 1, max: 170}), 'Required Field']
    ],
    message: () => [
      [validator.isLength(data.message.value, {min: 1, max: 170}), 'Required Field']
    ],
    url: () => [
      [validator.isLength(data.url.value, {min: 1, max: 170}), 'Required Field'],
      [validator.isURL(data.url.value), 'Must be in URL Format'],
    ]
  }
}

export function weblinkFormSchemaDraft(data: any) {
  let fixed: any = {
    title: () => [
      validator.isLength(data.title.value, {min: 0, max: 170}), 'Cannot exceed 170 characters'
    ],
    message: () => [
      validator.isLength(data.message.value, {min: 0, max: 170}), 'Cannot exceed 170 characters'
    ],
    url: () => [
      [validator.isLength(data.url.value, {min: 0, max: 170}), 'Cannot exceed 170 characters'],
    ]
  }

  if (data.url.value) {
    fixed.url = () => [
      [validator.isLength(data.url.value, {min: 0, max: 170}), 'Cannot exceed 170 characters'],
      [validator.isURL(data.url.value), 'Must be in URL Format'],
    ]
  }

  return fixed;
}

function isValid(validationArr: any) {
  return !Object.values(validationArr)
                .map((fn: any) => fn())
                .map((arr: any) => arr.filter((res: any) => res[0] === false).length)
                .some((err: number) => err > 0);
}