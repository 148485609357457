
import http from 'services/axios';

export default class BaseAPI {
  /**
   * Build a custom url by replacing properties (words that begin with a colon (eg, :id))
   * with matching values from the context object having the same key.
   */
  buildURL(url: string, context: {[key: string]: string}): string {
    let properties = url.match(/(:\w+)/g);
    if (properties) {
      properties.forEach((property) => {
        const key = property.substring(1);
        const value = context[key];
        if (value !== undefined) {
          url = url.replace(property, value);
        } else {
          throw new Error('buildURL: context.' + key + ' expected but undefined');
        }
      });
      return url;
    }
    return '';
  }

  GET(url: string, params?: object): Promise<any> {
    return http.get(url, { params });
  }

  PUT(url: string, data: object = {}): Promise<any> {
    return http.put(url, data);
  }

  POST(url: string, data: object = {}): Promise<any> {
    return http.post(url, data);
  }

  DELETE(url: string): Promise<any> {
    return http.delete(url);
  }
}
