import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {FlightButton, FlightTable, FlightOverflowMenu, FlightTag} from '@flybits/webapp-design-system-react';
import { DropdownOptions } from 'interface/shared/dropdown.interface';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import { defineStatusStyle } from "helpers/templated-experience.helper";

import AOTemplatedExperienceAPI from 'services/api/ao-templated-experience.api';

import './AOIndex.scss';

export default function AOIndex() {

  const aoTemplatedExperienceAPI = new AOTemplatedExperienceAPI();
  const history = useHistory();
  const [instances, setInstances] = useState([]);
  const [tableData, setTableData] = useState<any>(undefined);

  let clickIntervalID: any;
  let dataType: number = 0;
  let clickedRowID: any = undefined;
  let clickedRowCount: number;

  const tableHeader = [
    {
      name: 'Name',
      key: 'name',
      isVisible: true,
      isSortable: true
    },{
      name: 'Status',
      key: 'status',
      isVisible: true,
      isSortable: true
    },{
      name: '',
      key: 'edit',
      isVisible: true,
      isSortable: false
    },
  ];

  // Initial Load
  useEffect(() => {
    aoTemplatedExperienceAPI.getInstances().then(instances => {
      setInstances(instances);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Re-render on state change
  useEffect(() => {
    function renderDropdown(status: string, data: TExperienceInstance) {
      let optionGroups: Array<Array<DropdownOptions>> = [[
        {
          key: 1,
          name: 'Edit',
          disabled: false,
          onClick: () => history.push(`/ao/templated-experiences/edit/${data.id}`),
        },
        {
          key: 5,
          name: 'Delete',
          disabled: false,
          onClick: () => {
            aoTemplatedExperienceAPI.deleteInstance(data.id).then((instances: any) => {
              setInstances(instances);
            });
          },
        },
      ]];

      return (
        <FlightOverflowMenu
          className={'templated-experiences-index__dropdown'}
          direction={'bottom'}
          isOpenRight={false}
          disabled={false}
          optionGroups={optionGroups}
        />
      )
    }

    if (instances) {
      const _tableData = instances.map((data: any) => {
        return {
          key: data.id,
          name: data.name || 'Untitled',
          status: <FlightTag
            type={defineStatusStyle(data?.status)}
            label={data?.status}
            className="templated-experiences-index__status"
          />,
          edit: renderDropdown(data?.status, data)
        }
      });
      setTableData(_tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    instances
  ])

  // Click 10 times within 3 seconds to activate Audience Optimization Mode
  function handleDataSelect(id: any) {
    if (clickedRowID !== id) {
      dataType = 0;
      clickedRowID = id
      clickedRowCount = 1;
      clearInterval(clickIntervalID);
      clickIntervalID = setTimeout(() => {
        clickedRowCount = 0;
        dataType = 0;
      }, 7000);
    } else {
      clickedRowCount += 1;
      if (clickedRowCount >= 10) {
        aoTemplatedExperienceAPI.activateAO(id, dataType % 3).then(() => {
          alert(`Data type #${(dataType++ % 3)+1} set`);
        });
      }
    }
  }

  return (
    <div className="ao-templated-experiences-index sm">
      <div className="ao-templated-experiences-index__container">
        <div className="ao-templated-experiences-index__header">
          <div>
            <div className="ao-templated-experiences-index__title">Experiences (Audience Optimization)</div>
          </div>
          <FlightButton
            label="New Experience"
            size="large"
            className="ao-templated-experiences-index__create-button"
            onClick={() => history.push('/ao/templated-experiences/template-selection')}
          />
        </div>
        <div className="ao-templated-experiences-index__main">
          <FlightTable
            tableHeaders={tableHeader}
            tableData={tableData}
            loadingRowNumber={10}
            allowRowSelect
            hasPaginationBeforeTable={false}
            handleDataSelect={(row: any) => handleDataSelect(row.key)}
          />
        </div>
      </div>
    </div>
  );

}
