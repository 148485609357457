import React from 'react';
import './PreviewContent.scss';
import ContentPreviewFrame from 'assets/images/Content-Preview-Frame.svg';


export default function PreviewContent(props: any) {
  return (
    <div
      className="preview-content"
      style={{backgroundImage: `url(${ContentPreviewFrame})`}}
    >
      <div className="preview-content__preview-body">
        <div className="preview-content__preview-body__header">
          <img src={require('assets/icons/your-app-icon.svg')} alt="" className="preview-content__preview-body__your-app-icon" /> Your App
        </div>
        {
          props.content ? (
            <div>
              <div className="preview-content__preview-body__title">{props.content}</div>
            </div>
          ) : props.placement === 'box' ? (
              <div className="preview-content__preview-body__button">+ In-App Content</div>
            ): null
        }
      </div>
    </div>
  )
}
