import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import * as AuthActions from 'redux/auth/auth.action';
import useActions from 'hooks/useActions';
import useFeatureFlag from 'hooks/useFeatureFlag';

export default function PrivateRoute(props: any) {
  const authActions = useActions(AuthActions);
  const auth = useSelector((state: any) => state.auth);
  const featureFlag = useSelector((state: any) => state.featureFlag);

  useFeatureFlag();

  useEffect(() => {
    if (!auth.authenticated) {
      authActions.authenticateUser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    (auth.authenticated && featureFlag.isInitialized)
      ? <Route {...props} />
      : <> </>
  )
}
