import { Plugin } from 'interface/plugin/plugin.interface';

export const PluginActionType = {
  SET_PLUGINS: "SET_PLUGINS"
}

export interface PluginState {
  plugins: Plugin[] | null
}

interface SetPluginAction {
  type: typeof PluginActionType.SET_PLUGINS,
  payload: {
    plugins: Plugin[]
  }
}
