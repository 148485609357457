import { Plugin } from 'interface/plugin/plugin.interface';
import { startCase } from 'lodash';

// List of all predicates
const PREDICATES: {[key: string]: string} = {
  'boolEq': 'is equal to',
  'notEq': 'is not equal to',
  'eq': 'is equal to',
  'neq': 'is not',
  'ls': 'is less than',
  'lseq': 'is less than or equal to',
  'gr': 'is greater than',
  'greq': 'is greater than or equal to',
  'stringFoldEq': 'is',
  'stringFoldNeq': 'is not',
  'stringFoldContains': 'contains'
};

function _predicateToString(predicate: string) {
  return PREDICATES[predicate];
}

function _ctxToString(context: string) {
  let _context = context.split(".").slice(2).map(word => startCase(word));
  return `${_context[1]} (${_context[0]})`;
}

/**
 * returns a refined object of stringRep array separated by predicate (1st element) and arguments (2nd and 3rd element)
 * @param stringRepArr raw array format of stringRep example) ["is less than", "Percentage (Battery)", "30"]
 */
function _convertToPredicateObject(stringRepArr: string[]) {
  return {
    predicate: _predicateToString(stringRepArr[0]),
    arguments: [
      _ctxToString(stringRepArr[1]),
      stringRepArr[2]
    ]
  }
}

/**
 * convert a stringRepresentation into an array of human readable elements
 * @param stringRep stringRepresentation of the rule
 * @returns {operator: string, data: array}
 * the first returned value represents a string either "And" or "Or",
 * the second returned value is an array of array, where each nested array is a 3 element array containing transpiled information of the rule.
 * ["is equal to", "Percentage (Battery)", "30"]
 */
export function ruleToString(stringRep: string) {
  const isOr: any = stringRep.indexOf("Or") > -1;
  stringRep = stringRep.replace(isOr ? /Or/g : /And/g, ''); // remove AND / OR
  const stringRepArr: string[] = stringRep.substring(1, stringRep.length - 1).split(" ").filter(a => !!a);
  const regex = /[(),]/g
  const arr = stringRepArr.map((_stringRep) => {
    const a = _stringRep.replace(regex, ' ').trim().split(" "); // ["eq", "ctx.sdk.battery.percentage", "30"]
    return _convertToPredicateObject(a); // ["is equal to", "Percentage (Battery)", "30"]
  });
  return {
    operator: isOr ? 'Or' : 'And',
    data: arr,
  }
}

export function ruleToArr(stringRep: string) {
  const isOr: any = stringRep.indexOf("Or") > -1;
  stringRep = stringRep.replace(isOr ? /Or/g : /And/g, ''); // remove AND / OR
  const stringRepArr: string[] = stringRep.substring(1, stringRep.length - 1).split(" ").filter(a => !!a);
  const regex = /[(),]/g
  const arr = stringRepArr.map((_stringRep: any) => ({
    ruleStringRepresentation: _stringRep, // boolEq(ctx.sdk.activity.cycling,true)
    delimittedRuleStringRepresentation: _stringRep.replace(regex, ' ').trim().split(" ") // ["boolEq", "ctx.sdk.activity.cycling", "true"]
  }));
  return {
    operator: isOr ? 'Or' : 'And',
    data: arr,
  }
}

export function findPluginByUID(plugins: Plugin[], uid: string) {
  return plugins.filter((plugin) => plugin.uid === uid);
}
