import React from 'react';

import { getPushType, getPushPayload } from 'helpers/templated-experience.helper';
import FlowBox from 'components/FlowBox/FlowBox';
import WebIcon from "assets/icons/icon_web.svg";
import DeviceIcon from "assets/icons/device.svg";

export default function PushDetailBox(props: any) {

  function DetailBox() {
    const pushType = getPushType(props);
    switch (pushType) {
      case 'basic':
        return (
          <FlowBox
            {...props}
            type="App Home Screen"
            icon={DeviceIcon}
            placeholder="Tapping the notification will lead to your app's home screen"
          />
        );
      case 'weblink':
        return (
          <FlowBox
            {...props}
            type="Weblink"
            icon={WebIcon}
            placeholder={getPushPayload(props).data.url}
            onBoxClick={props!!.onBoxClick}
          />
        )
    }
  }

  return (
    <DetailBox/>
  )
}