
import Session from 'services/session';
import AuthAPI from 'services/api/auth.api';


import { User } from 'interface/user/user.interface';
import {
  AuthActionType,
  AuthorizeUserAction,
  UnAuthorizeUserAction
} from 'redux/auth/auth.type';

export function authorizeUser(payload: {user: User, token: string}): AuthorizeUserAction {
  return {
    type: AuthActionType.AUTHORIZE_USER,
    payload,
  }
}

export function unauthorizeUser(): UnAuthorizeUserAction {
  return {
    type: AuthActionType.UNAUTHORIZE_USER,
  }
}

/**
 * By default, this function will run validation based on the token set in web storage.
 * However, if token is passed, it will override the existing token in web storage before
 * verifying with the back-end.
 * @param {string} token
 */
export function authenticateUser(token = '') {
  const session = new Session();
  return async (dispatch: any) => {
    let _token: string;

    if (token.length === 0) {
      _token = await session.getToken() as string;
    } else {
      await session.setToken(token!!);
      _token = token!!;
    }

    return new Promise((resolve, reject) => {
      const authAPI = new AuthAPI();
      authAPI.validateToken().then(user => {
        dispatch(authorizeUser({ token: _token, user }));
        resolve();
      }).catch(() => {
        reject();
      })
    });
  }
}
