import React, { useEffect } from 'react';

import { getPushPayload } from 'helpers/templated-experience.helper';

import { Action } from 'interface/experience/experience.interface';
import { IForm } from 'interface/shared/form.interface';

import { basicFormSchema, basicFormSchemaDraft } from 'validator/push-message.validator';
import useForm from 'hooks/useForm';

import {
  FlightTextInput,
  FlightTextArea,
} from '@flybits/webapp-design-system-react';


interface IProps {
  action: Action;
  isDraft: boolean;
  onFormChange: (formData: IForm) => void;
}

export default function BasicForm(props: IProps) {

  const payload = getPushPayload(props.action);

  const [data, onFieldChange, onFieldBlur, onFormSubmit] = useForm({
    formData: {
      title: payload.title.en,
      message: payload.alert.en,
    },
    formSchema: basicFormSchema,
    formSchemaDraft: basicFormSchemaDraft,
    isDraft: props.isDraft,
    validateOnLoad: props.action.hasError,
  });

  // Notify parent view to support live preview update
  useEffect(() => {
    props.onFormChange({
      type: 'basic',
      data,
      onFormSubmit: onFormSubmit,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <FlightTextInput
        className="create-edit-push__input"
        label="Title"
        type="text"
        hasError={data.title.hasError}
        errorMessage={data.title.error}
        placeholderText="Title"
        width="100%"
        value={data.title.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFieldChange('title', e.target.value)}
        onBlur={() => onFieldBlur('title')}
      />
      <FlightTextArea
        className="create-edit-push__input"
        label="Message"
        hasError={data.message.hasError}
        errorMessage={data.message.error}
        maxLength={170}
        width="100%"
        value={data.message.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFieldChange('message', e.target.value)}
        onBlur={() => onFieldBlur('message')}
      />
    </>
  )
}
