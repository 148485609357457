
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { upperFirst } from 'lodash';

import { Dot, ResponsiveContainer, CartesianGrid, AreaChart, Legend, XAxis, YAxis, Tooltip, Area } from 'recharts';
import { FlightSelect } from '@flybits/webapp-design-system-react';
import './AnalyticsPerformanceChart.scss';

interface IProp {
  instance: any;
}

const dateOptions = [
  {key: 'days_7', value: 7, name: 'Last 7 days'},
  {key: 'days_14', value: 14, name: 'Last 14 days'}
]

// For now, the content of this graph is hard coded for PNC Demo.
export default function AnalyticsPerformanceChart(props: IProp) {
  const isEmpty = props.instance.audienceOptimization.isEnabled === false;
  const [selectedDateRange, setDateRange] = useState(dateOptions[0]);
  const [chartData, setChartData] = useState<any>(generateDataPoints(selectedDateRange.value));

  useEffect(() => {
    setChartData(generateDataPoints(selectedDateRange.value));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDateRange]);

  function handleOptionClick(option: any) {
    setDateRange(option);
    setChartData(generateDataPoints(option.value));
  }

  // build data points for last n days (default: last 7 days)
  function generateDataPoints(days: number = 7) {
    const points = props.instance.analytics.audienceGraph;
    let from = moment().subtract(days, 'days').format('YYYY-MM-DD');
    const to = moment().format('YYYY-MM-DD');
    const dates = [];
    while (from <= to) {
      dates.push(from);
      from = moment(from).add(1, 'day').format('YYYY-MM-DD');
    }
    return dates.map((date: string, idx: number) => ({
      date,
      users: points[points.length - (days - idx) - 1][0],
      engagements: points[points.length - (days - idx) - 1][1],
    }));
  }

  return (
    <div className="analytics-performance-chart">
      <div className="top">
        <p className="headline"> Audience Performance </p>
        <FlightSelect
          label=""
          className="day-range-picker"
          options={dateOptions}
          selected={selectedDateRange}
          handleOptionClick={handleOptionClick}
          hasLabelAnimation
          disabled={isEmpty}
        />
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={chartData}
          margin={{ top: 50, left: 0, bottom: 20 }}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#B9D9FF" stopOpacity={1}/>
              <stop offset="95%" stopColor="#B9D9FF" stopOpacity={0.5}/>
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#0851DF" stopOpacity={1}/>
              <stop offset="95%" stopColor="#0851DF" stopOpacity={0.5}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date" tickLine={false}
            ticks={[chartData[0].date, chartData[chartData.length - 1].date]}
            tickFormatter={(e: any) => moment(e).format('LL')} allowDataOverflow={true}
            />
          <YAxis tickLine={false} width={80}/>
          <Legend align="left" verticalAlign="top" iconType="square" height={70} formatter={(legend: string) => upperFirst(legend)}/>
          <Tooltip />
          <Dot cx={100} cy={100} r={50} className="dot" />
          <Area type="monotone" dataKey="users" stroke="#B9D9FF" fillOpacity={1} fill="url(#colorUv)" />
          <Area type="monotone" dataKey="engagements" stroke="#0851DF" fillOpacity={1} fill="url(#colorPv)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
