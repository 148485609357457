import React, { useState } from 'react';

import { Action } from "interface/experience/experience.interface";
import { IForm } from 'interface/shared/form.interface';

import CreateEditPush from "components/PushMessage/CreateEditPush/CreateEditPush";
import EditModal from "components/Modal/EditModal/EditModal";
import PreviewPush from 'components/PushMessage/PreviewPush/PreviewPush';

interface IProps {
  action: Action;
  isVisible: boolean;
  onApply: (form: IForm) => void;
  onCancel: () => void;
}

export default function PushEditModal(props: IProps) {

  const [onSubmitForm, setOnSubmitForm] = useState<(cb: any) => void>(() => {});
  const [formData, setFormData] = useState<any>({
    type: '',
    title: {
      value: '',
    },
    message: {
      value: '',
    }
  });

  function onFormChangeHandle(form: IForm) {
    setFormData({
      type: form.type,
      ...form.data,
    });
    setOnSubmitForm(form.onFormSubmit);
  }

  return (
    <EditModal
      size="large"
      icon="push.svg"
      title="Configure your Message"
      isVisible={props.isVisible}
      primaryButton={{
        name: 'Apply',
        onClick: () => onSubmitForm(() => props.onApply(formData)),
        disabled: false
      }}
      cancelButton={{
        name: 'Cancel',
        onClick: props.onCancel
      }}
    >
      <CreateEditPush
        action={props.action}
        onFormChange={onFormChangeHandle}
      />
      <PreviewPush
        title={formData.title.value}
        message={formData.message.value}
      />
    </EditModal>
  )
}
