import React, { useEffect, useState } from 'react';
import { Action } from 'interface/experience/experience.interface';

import { getPushType } from 'helpers/templated-experience.helper';

import BasicForm from 'components/PushMessage/CreateEditPush/BasicForm/BasicForm';
import WebLinkForm from 'components/PushMessage/CreateEditPush/WebLinkForm/WebLinkForm';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { FlightSelect } from '@flybits/webapp-design-system-react';
import './CreateEditPush.scss';

import { IForm } from 'interface/shared/form.interface';

interface IProp {
  action: Action;
  onFormChange: (formData: IForm) => void;
}

export default function CreateEditPush(props: IProp) {

  const notificationOptions = [{
    key: 'basic',
    name: 'Basic',
  }, {
    key: 'weblink',
    name: 'Weblink',
  }];

  const [selectedNotificationType, setSelectedNotificationType] = useState<any>(null);

  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);

  const location = useLocation();

  const templatedExperience = location.pathname.indexOf('edit') > -1
    ? reduxTemplatedExperienceState.instance
    : reduxTemplatedExperienceState.template

  useEffect(() => {
    const pushType = getPushType(props.action);
    setSelectedNotificationType(notificationOptions.find(option => option.key === pushType));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOptionClick(option: any) {
    setSelectedNotificationType(option);
  }

  function renderForm() {
    const isDraft = ['Draft', '', undefined].includes(templatedExperience.status);
    const validateOnLoad = templatedExperience.showError && templatedExperience.incompleteCount > 0;
    const formProps = {...props, isDraft, validateOnLoad}
    switch(selectedNotificationType.key) {
      case 'basic':
        return <BasicForm {...formProps}/>;
      case 'weblink':
        return <WebLinkForm {...formProps}/>;
      default:
        return <BasicForm {...formProps}/>;
    }
  }

  return (
    <div className="create-edit-push">
      <div className="create-edit-push__heading">
        Notification Type
      </div>
      <FlightSelect
        label=""
        options={notificationOptions}
        selected={selectedNotificationType}
        hasLabelAnimation
        handleOptionClick={handleOptionClick}
        dropdownMaxHeight="150px"
        width="252px"
      />
      <div className="create-edit-push__heading">
        Message Details
      </div>
      <div className="create-edit-push__form">
        {selectedNotificationType && renderForm()}
      </div>
    </div>
  )
}
