import React from 'react';
import './PreviewPush.scss';
import PushPreviewFrameFull from 'assets/images/Push-Preview-Frame-Full.svg';
import { FlightButton } from '@flybits/webapp-design-system-react';

export default function PreviewPush(props: any) {
  return (
    <div
      className="preview-push"
      style={{backgroundImage: `url(${PushPreviewFrameFull})`}}
    >
      <div className="preview-push__preview-body">
        <div className="preview-push__preview-body__header">
          <img src={require('assets/icons/your-app-icon.svg')} alt="" className="preview-push__preview-body__your-app-icon" /> Your App
        </div>
        {
          props.title || props.message ? (
            <div>
              <div className="preview-push__preview-body__title">{props.title}</div>
              <div className="preview-push__preview-body__body">{props.message}</div>
            </div>
          ) : props.placement === 'box' ? (
                <FlightButton
                  type="secondary"
                  iconLeft="add"
                  onClick={()=>{}}
                  label="Create Notification"
                  className="preview-push__preview-body__button"
                />
            ): null
        }

      </div>
    </div>
  )
}