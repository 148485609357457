import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import authReducer from 'redux/auth/auth.reducer';
import pluginReducer from 'redux/plugin/plugin.reducer';
import templatedExperienceReducer from './templated-experience/templated-experience.reducer';
import snackbarReducer from './snackbar/snackbar.reducer';
import featureFlagReducer from './feature-flag/feature-flag.reducer';

export default combineReducers({
  auth: authReducer,
  plugin: pluginReducer,
  snackbar: snackbarReducer,
  templatedExperience: templatedExperienceReducer,
  featureFlag: featureFlagReducer,
  router: routerReducer,
});
