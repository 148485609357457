import React, { useEffect } from 'react';
import './ControlBar.scss';
import { FlightButton, FlightTag } from '@flybits/webapp-design-system-react';
import { defineStatusStyle } from 'helpers/templated-experience.helper';
import {TExperienceInstance} from "interface/templated-experience/templated-experience.interface";

interface IProps {
  name?: string;
  status?: string;
  onLaunch?: () => void;
  onLaunchDisabled?: boolean;
  onSave?: () => void;
  onSaveDisabled?: boolean;
  onCancel?: () => void;
  onDraft?: (schedule: TExperienceInstance['schedule']) => void;
  onDraftDisabled?: boolean;
  onDeactivate?: () => void | undefined;
  onEdit?: (id: string) => void;
  onNameEdit?: (name: string) => void;
  children: React.ReactNode;
}

export default function ControlBar(props: IProps) {
  useEffect(() => {
    if (props.onNameEdit) {
      const input = document.querySelector('input');
      input!!.focus()
    }
  });

  return (
    <div className="control-bar">
      <div className="control-bar__header">
        <div className="control-bar__header-left">
          {props.onNameEdit && (
            <input type="text" value={props.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onNameEdit!!(e.target.value)} placeholder="Untitled"/>
          )}
        </div>
        <div className="control-bar__header-right">
          {props.status && (
            <p className="control-bar__status">
              Status: <FlightTag type={defineStatusStyle(props.status)} label={props.status} className="control-bar__status__text"/>
            </p>
          )}
          {props.onCancel && (
            <FlightButton
              label="Cancel"
              theme="secondary"
              size="large"
              onClick={() => props.onCancel!!()}
            />
          )}
          {props.onDraft && props.status === "Draft" && (
            <FlightButton
              label="Save as Draft"
              type="secondary"
              size="large"
              disabled={props.onDraftDisabled}
              onClick={(schedule) => props.onDraft!!(schedule)}
            />
          )}
          {props.onSave && (
            <FlightButton
              label="Save"
              size="large"
              disabled={props.onSaveDisabled}
              onClick={() => props.onSave!!()}
            />
          )}
          {props.onDeactivate && (
            <FlightButton
              label="Deactivate"
              size="large"
              onClick={() => props.onDeactivate!!()}
            />
          )}
          {props.onLaunch && (
            <FlightButton
              label="Launch"
              size="large"
              disabled={props.onLaunchDisabled}
              onClick={() => props.onLaunch!!()}
            />
          )}
          {props.onEdit && (
            <FlightButton
              label="Edit"
              size="large"
              onClick={props.onEdit}
            />
          )}
        </div>
      </div>
      <div className="control-bar__main">
        {props.children}
      </div>
    </div>
  )
}