import React, {useState} from "react";
import { useSelector } from "react-redux";
import { FlightCard, FlightTooltip } from "@flybits/webapp-design-system-react";
import './FlowBox.scss';

import ArrowIcon from 'assets/icons/arrow.svg';
import LockIcon from "assets/icons/icons8-lock.svg";

import { useLocation } from 'react-router-dom';
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import {trackEvent} from "helpers/analytics.helper";
import {history} from "redux/store";
import useActions from "hooks/useActions";
import * as TExperienceActions from "redux/templated-experience/templated-experience.action";

// TODO. add props interface
export default function FlowBox(props: any) {

  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const location = useLocation();
  const confirmModalProps = {
    isVisible: false,
    icon: "",
    title: "Your Experience will be Deactivated",
    content: "You can't edit experiences that are Active. " +
      "Proceeding will deactivate your experience immediately and it will no longer be visible to your customers.",
    primaryButton: {
      name: 'Deactivate and Edit',
      onClick: () => onConfirmHandle(),
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    }
  }
  const [modalProps, setModalProps] = useState(confirmModalProps);

  function onConfirmHandle() {
    const instance_id = reduxTemplatedExperienceState.instance?.id;
    reduxTemplatedExperienceAction.deactivateTemplatedExperienceInstance(instance_id).then(() => {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Deactivate and Edit',
      });
      history.push(`/templated-experiences/edit/${instance_id}`);
    });
    setModalProps({...modalProps, isVisible: false});
  }

  const templatedExperience = location.pathname.indexOf('edit') > -1
    ? reduxTemplatedExperienceState.instance
    : reduxTemplatedExperienceState.template

  let className = 'flow-box__card-container__button';
  if (props.type === 'App Home Screen' || props.isNotEditable) {
    className += ' not-clickable';
  }
  if (props.hasError && templatedExperience.showError) {
    className += ' error';
  }
  return (
    <div className="flow-box__card-container">
      <button
        className={className}
        onClick={props.isNotEditable ?
          ()=>setModalProps({...modalProps, isVisible: true}) :
          props.onBoxClick
        }
      >
        <FlightCard
          className="flow-box__card-container__card"
          header={
            <div className="flow-box__card-container__card__step-header">
              <div className="flow-box__card-container__card__step-header__type-box">
                <img className="flow-box__card-container__card__step-header__icon" src={props.icon} alt="boxIcon"/>
                <div> {props.type} </div>
              </div>
              {props.tooltip && (
                <FlightTooltip
                  className="custom-class-name"
                  direction="top"
                  isEnabled={true}
                  description={props.tooltip}
                  delay={0}
                >
                  <img src={LockIcon} alt="QuestionIcon" className="flow-box__card-container__card__step-header__question"/>
                </FlightTooltip>
              )}
            </div>
          }
          body={
            <div className="flow-box__card-container__card__step-body">
              {/* If there is props.body, we do not render placeholder */}
              {props.body ? (
                props.body
              ) : (
                <div className="flow-box__card-container__card__step-body__placeholder">
                  {props.placeholder}
                </div>
              )}
            </div>
          }
          actionables={props.actionables}
        />
      </button>

      <img className="flow-box__card-container__arrow" src={ArrowIcon} alt="ArrowIcon"/>
      <ConfirmModal {...modalProps} />
    </div>
  )
}