
import { useReducer, useEffect } from 'react';
import { forEach } from 'lodash';

export default function useForm({formData, formSchema, formSchemaDraft, isDraft, validateOnLoad}: {
  formData: object,
  formSchema: any,
  formSchemaDraft: any,
  isDraft: boolean,
  validateOnLoad: boolean
}) {

  const schema = isDraft ? formSchemaDraft : formSchema;

  function validationReducer(state: any, action: any) {
    switch(action.type) {
      case 'changeField': {
        const key = action.payload.key;
        return {
          ...state,
          [key]: {
            ...state[key],
            value: action.payload.value,
          }
        }
      }
      case 'validateField': {
        const key = action.payload.key;
        if (!action.payload.force) {
          if (!state[key].isDirty) {
            return {
              ...state,
            }
          }
        }
        const errors = schema(state)[key]().filter((res: any) => res[0] === false);
        const hasError = errors.length > 0;
        return {
          ...state,
          [key]: {
            ...state[key],
            isDirty: true,
            hasError,
            error: hasError ? errors[0][1] : '',
          }
        }
      }
      case 'validateForm': {
        let formValid = true;
        forEach(state, (value: any, key: string) => {
          const errors = action.payload.schema(state)[key]().filter((res: any) => res[0] === false);
          const hasError = errors.length > 0;
          if (hasError) formValid = false;
          state = {
            ...state,
            [key]: {
              ...state[key],
              isDirty: true,
              hasError,
              error: hasError ? errors[0][1] : '',
            }
          }
        });
        // invoke callback if form is valid
        if (formValid && action.payload && action.payload.callback) {
          action.payload.callback();
        }
        return state;
      }
      default:
        break;
    }
  }

  const [state, dispatch] = useReducer(validationReducer, _convertToFormObject(formData));

  useEffect(() => {
    if (validateOnLoad) {
      dispatch({
        type: 'validateForm',
        payload: {
          schema: formSchema,
        }
      });
    }
   }, [validateOnLoad, formSchema]);

  function onFieldChange (key: string, value: any) {
    dispatch({
      type: 'changeField',
      payload: {
        key,
        value
      }
    });
    dispatch({
      type: 'validateField',
      payload: {
        key
      }
    })
  }

  function onFieldBlur (key: string) {
    dispatch({
      type: 'validateField',
      payload: {
        key,
        force: true,
      }
    })
  }

  function onFormSubmit () {
    return (callback: () => void) => {
      dispatch({
        type: 'validateForm',
        payload: {
          callback,
          schema,
        }
      });
    }
  }

  return [
    state,
    onFieldChange,
    onFieldBlur,
    onFormSubmit,
  ]
}

function _convertToFormObject(data: any) {
  var formData: {[key: string]: any} = {};
  forEach(data, (value: any, key: string) => {
    formData[key] = {
      value,
      isDirty: false,
      hasError: false,
      error: '',
    }
  });
  return formData;
}
