import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { times } from 'lodash';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import Skeleton from 'react-loading-skeleton';
import FinishIcon from 'assets/icons/end.svg';
import ControlBar from 'components/ControlBar/ControlBar';
import AudienceBox from 'components/FlowBox/AudienceBox/AudienceBox';

import './TemplatedExperienceView.scss';
import { history } from 'redux/store';
import Storage from "services/storage";
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import {trackEvent} from "helpers/analytics.helper";
import ScheduleInline from "../../../components/ScheduleInline/ScheduleInline";
import ScheduleEditModal, {scheduleDataType} from "../../../components/Modal/EditModal/ScheduleEditModal/ScheduleEditModal";
import {transformScheduleData} from "../../../helpers/templated-experience.helper";
const storage = new Storage();

interface param {
  [key: string]: string | undefined
}

export default function TemplatedExperienceView() {

  const { id }: param = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const confirmModalProps = {
    isVisible: false,
    title: "Your Experience will be Deactivated",
    icon: "warning.svg",
    content: "You can't edit experiences that are Active. " +
      "Proceeding will deactivate your experience immediately " +
      "and it will no longer be visible to your customers.",
    primaryButton: {
      name: "Deactivate and Edit",
      onClick: () => onDeactivateHandle(),
      disabled: false,
    },
    cancelButton: {
      name: "Cancel",
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    }
  }
  const [modalProps, setModalProps] = useState(confirmModalProps);


  const scheduleModalProps = {
    isVisible: false,
    title: 'Schedule Activation',
    instance: {},
    primaryButton: {
      name: 'Confirm',
      onClick: (scheduleData: scheduleDataType) => setScheduleData(scheduleData),
      disabled: false,
      loading: false,
    },
    cancelButton: {
      name: '',
      onClick: () => onScheduleCancel(),
    }
  }
  const [scheduleProps, setScheduleProps] = useState(scheduleModalProps);

  useEffect(() => {
    reduxTemplatedExperienceAction.fetchInstance(id).then(() => setIsLoaded(true));
    return () => reduxTemplatedExperienceAction.clearInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function onDeactivateHandle() {
    reduxTemplatedExperienceAction.deactivateTemplatedExperienceInstance(id).then(() => {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Deactivate and Edit',
      });
      history.push(`/templated-experiences/edit/${id}`);
    });
    setModalProps({...modalProps, isVisible: false});
  }

  function onSaveHandle() {
    const schedule = reduxTemplatedExperienceState.instance.schedule;
    reduxTemplatedExperienceAction.updateInstanceSchedule({schedule}).then(() => {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Change schedule on Active and Save',
      });
      history.push('/templated-experiences');
    });
  }

  function onEditHandle() {
    if (reduxTemplatedExperienceState.instance.status === 'Active') {
      setModalProps({...modalProps, isVisible: true});
    } else {
      history.push(`/templated-experiences/edit/${id}`);
    }
  }

  function onCancelHandle() {
    storage.setItem('instance', null);
    history.push('/templated-experiences');
  }

  function renderStep(instance: any, idx: number) {
    const props = {
      ...instance,
      key: `step_${idx}`,
      isNotEditable: true,
      tooltip: "You can't edit experiences that are Active. " +
        "You need to deactivate your experience in order to edit this component.",
    };
    if (instance.isRoot) {
      return <AudienceBox {...props} />
    }
  }

  function onScheduleHandle() {
    setScheduleProps({
      ...scheduleProps,
      isVisible: true
    });
  }

  function setScheduleData(scheduleData:scheduleDataType) {
    reduxTemplatedExperienceAction.setSchedule(transformScheduleData(scheduleData));
    setScheduleProps({
      ...scheduleProps,
      isVisible: false,
    });
  }

  function onScheduleCancel() {
    setScheduleProps({
      ...scheduleProps,
      isVisible: false,
    });
  }


  return (
    <ControlBar
      name={reduxTemplatedExperienceState.instance?.name || ''}
      status={reduxTemplatedExperienceState.instance?.status || 'Draft'}
      onCancel={onCancelHandle}
      onSave={onSaveHandle}
      onEdit={onEditHandle}
      onNameEdit={()=>{}}
    >
      <div className="templated-experiences-edit">
        <div className="templated-experiences-edit__main">
          {reduxTemplatedExperienceState.isLoading ? (
            <div className="templated-experiences-edit__main__progress">
              {times(3, (i) => (
                <div key={i} style={{ marginRight: 100}}>
                  <Skeleton width={300} height={300} />
                </div>
              ))}
            </div>
          ) : isLoaded && reduxTemplatedExperienceState.instance ? (
            <>
              <ScheduleInline showScheduleModal={onScheduleHandle} experience={reduxTemplatedExperienceState?.instance}/>
              <div className="templated-experiences-edit__main__flow">
                {reduxTemplatedExperienceState.instance.steps?.map((_instance: any, idx: number) => renderStep(_instance, idx))}
                <div>
                  <img className='templated-experiences-edit__finish-icon' src={FinishIcon} alt="FinishIcon"/>
                </div>
              </div>
            </>
        ) : isLoaded && reduxTemplatedExperienceState.errorMessage ? (
          <div className="templated-experiences-edit__main__error">Error: {reduxTemplatedExperienceState.errorMsg}</div>
        ) : isLoaded && (
          <div className="templated-experiences-edit__main__empty"> There are no template </div>
        )}
        </div>
        <ConfirmModal {...modalProps} />
        <ScheduleEditModal {...scheduleProps} schedule={reduxTemplatedExperienceState?.instance?.schedule}/>
      </div>
    </ControlBar>
  )
}
