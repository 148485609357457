import Storage from './storage'
import store from '../redux/store';
import { AuthActionType } from 'redux/auth/auth.type';

class Session {
  private storage: Storage;
  constructor() {
    this.storage = new Storage();
  }

  getToken(): Promise<any> {
    return this.storage.getItem('token');
  }

  setToken(token: string): Promise<any> {
    return this.storage.setItem('token', token);
  }

  async removeToken(): Promise<any> {
    await this.storage.removeItem('token');
  }

  async unauthorizeUser() {
    await this.removeToken();
    store.dispatch({ type: AuthActionType.UNAUTHORIZE_USER });
  }
}

export default Session;
