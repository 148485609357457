
import BaseAPI from './base.api';

import { User } from 'interface/user/user.interface';

export default class AuthAPI extends BaseAPI {
  private ROUTES = {
    me: '/sso/auth/me'
  };

  validateToken(): Promise<User> {
    return this.GET(this.ROUTES.me);
  }
}