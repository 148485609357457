
import DeveloperHubIndex from './DeveloperHub/DeveloperHubIndex';

import HomeIndex from './Home/HomeIndex';
import RedirectIndex from './Redirect/RedirectIndex';

import TemplatedExperienceIndex from './TemplatedExperience/Index/TemplatedExperienceIndex';
import TemplatedExperienceCreate from './TemplatedExperience/Create/TemplatedExperienceCreate';
import TemplatedExperienceEdit from './TemplatedExperience/Edit/TemplatedExperienceEdit';
import TemplatedExperienceView from './TemplatedExperience/View/TemplatedExperienceView';
import TemplateSelection from './TemplatedExperience/TemplateSelection/TemplateSelection';

// Audience Optimization Demo
import AOIndex from './AODemo/Index/AOIndex';
import AOSelect from './AODemo/Select/AOSelect';
import AOCreate from './AODemo/Create/AOCreate';
import AOEdit from './AODemo/Edit/AOEdit';

import NotFoundIndex from './NotFound/NotFoundIndex';
import NoAccess from './NoAccess/NoAccess';

export default {
  DeveloperHubIndex,

  HomeIndex,
  RedirectIndex,
  TemplatedExperienceIndex,
  TemplatedExperienceCreate,
  TemplatedExperienceEdit,
  TemplatedExperienceView,
  TemplateSelection,

  AOIndex,
  AOSelect,
  AOCreate,
  AOEdit,

  NoAccess,
  NotFoundIndex
}