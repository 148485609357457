import { User } from 'interface/user/user.interface';

export const AuthActionType = {
  AUTHORIZE_USER: 'AUTHORIZE_USER',
  UNAUTHORIZE_USER: 'UNAUTHORIZE_USER'
}

// Redux State
export interface AuthState {
  user: User | undefined,
  token: string | undefined,
  authenticated: boolean,
}

export interface AuthorizeUserAction {
  type: typeof AuthActionType.AUTHORIZE_USER,
  payload: {
    user: User,
    token: string
  }
}

export interface UnAuthorizeUserAction {
  type: typeof AuthActionType.UNAUTHORIZE_USER
}