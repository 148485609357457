
import { Content } from 'interface/content/content.interface';
import { PaginationResponse } from 'interface/shared/api.interface';
import { TExperience, TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import {
  TExperienceActionType,
  SetTemplateAction,
  SetInstancesAction,
  SetLoadingAction,
  SetErrorAction,
  SetModifiedAction,
  SetInstanceAction,
  UpdateInstancesAction
 } from 'redux/templated-experience/templated-experience.type';

import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { getIncompleteCount, validateTemplatedExperience } from 'validator/templated-experience.validator';
import { findActionParent, convertToInstance, getPushPayload, generateUUID, updateEmptyContentName, cleanupTimezone } from "helpers/templated-experience.helper";
import { showSnackbar } from "redux/snackbar/snackbar.action";
import { ExperienceSchedule } from 'interface/experience/experience.interface';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {trackEvent} from "../../helpers/analytics.helper";

function setLoading(payload: { flag: boolean }): SetLoadingAction {
  return {
    type: TExperienceActionType.SET_LOADING,
    payload,
  }
}

function setError(payload: { errorMessage: any }): SetErrorAction {
  return {
    type: TExperienceActionType.SET_ERROR,
    payload,
  }
}

export function setTemplate(payload: { template: TExperienceInstance | null }): SetTemplateAction {
  return {
    type: TExperienceActionType.SET_TEMPLATE,
    payload
  }
}

export function setInstance(payload: { instance: TExperienceInstance | any }): SetInstanceAction {
  return {
    type: TExperienceActionType.SET_INSTANCE,
    payload
  }
}

function setInstances(payload: { instances: PaginationResponse<TExperienceInstance> }): SetInstancesAction {
  return {
    type: TExperienceActionType.SET_INSTANCES,
    payload
  }
}

export function setModified(payload: boolean): SetModifiedAction {
  return {
    type: TExperienceActionType.SET_MODIFIED,
    payload,
  }
}

export function updateInstances(payload: {id: string, status: string}): UpdateInstancesAction {
  return {
    type: TExperienceActionType.UPDATE_INSTANCES,
    payload,
  }
}

/**
 * This function is responsible for modifying the value of "actionBody" of action with corresponding actionID.
 * Any modification to the actionBody should be done through this function to streamline the way we access and modify
 * data in templatedExperience data in redux.
 * @param actionID the id of the box/card
 * @param callback the json object that should replace the actionBody of action with actionID
 */
function _updateActionBody(actionID: string, callback: () => object) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { templatedExperience } = getState();
    let { template, instance } = templatedExperience;
    if (!isEmpty(template)) { // update template (create route)
      const [parentIdx, childIdx] = findActionParent(template, actionID);
      template.steps[parentIdx].actions[childIdx].actionBody = callback();
      template = validateTemplatedExperience(template);
      dispatch(setTemplate({
        template: {
          ...template,
          incompleteCount: getIncompleteCount(template),
          showError: getIncompleteCount(template) === 0 ? false : template.showError
        }
      }));
    } else { // update instance (edit route)
      const [parentIdx, childIdx] = findActionParent(instance, actionID);
      instance.steps[parentIdx].actions[childIdx].actionBody = callback();
      instance = validateTemplatedExperience(instance);
      dispatch(setInstance({
        instance: {
          ...instance,
          incompleteCount: getIncompleteCount(instance),
          showError: getIncompleteCount(instance) === 0 ? false : instance.showError
        }}
      ));
    }
  };
}

export function setSchedule(schedule: TExperienceInstance['schedule']) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const {templatedExperience} = getState();
    let {template, instance} = templatedExperience;
    if (!isEmpty(template)) {
      dispatch(setTemplate({
        template: {
          ...template,
          schedule
        }
      }));
    } else {
      dispatch(setInstance({
        instance: {
          ...instance,
          schedule
        }}
      ));
    }
  }
}

export function clearTemplate() {
  return (dispatch: any) => {
    dispatch(setTemplate({template: null}));
  }
}

export function clearInstance() {
  return (dispatch: any) => {
    dispatch(setInstance({instance: null}));
  }
}

export function fetchInstances(opts?: { limit: number, offset: number }) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({flag: true}));
      templatedExperienceAPI.getTemplatedInstances(opts).then((data: PaginationResponse<TExperienceInstance>) => {
        dispatch(setInstances({instances: data}));
        resolve(data);
      }).catch((err: any) => {
        dispatch(setError({errorMessage: err?.response?.data ? err.response.data : err.message}));
        reject();
      }).finally(() => {
        dispatch(setLoading({flag: false}));
      });
    })
  }
}

export function fetchInstance(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return async (dispatch: any) => {
    return new Promise( (resolve, reject) => {
      dispatch(setLoading({flag: true}));
        templatedExperienceAPI.getTemplatedExperienceInstance(id).then((instance: TExperienceInstance) => {
          let _instance = validateTemplatedExperience(instance);
          _instance = {
            ..._instance,
            incompleteCount: getIncompleteCount(_instance)
          };
          dispatch(setInstance({
            instance: _instance
          }));
          resolve(_instance);
        }).catch((err: any) => {
          dispatch(setError({errorMessage: err?.response?.data ? err.response.data : err.message}));
          reject();
        }).finally(() => {
          dispatch(setLoading({flag: false}));
        });
    })
  }
}

export function fetchTemplate(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({flag: true}));
      templatedExperienceAPI.getTemplatedExperience(id).then((template: TExperience)  => {
        let _template: TExperienceInstance = convertToInstance(template);
        _template = {
          ...validateTemplatedExperience(_template),
          status: "Draft",
          incompleteCount: getIncompleteCount(_template)
        }
        dispatch(setTemplate({
          template: _template
        }));
        resolve(_template);
      }).catch((err: any) => {
        dispatch(setError({errorMessage: err?.response?.data ? err.response.data : err.message}));
        reject();
      }).finally(() => {
        dispatch(setLoading({flag: false}));
      });
    })
  }
}

export function createTemplatedInstance(payload: {
  status: string,
  schedule: ExperienceSchedule
} = {
  status: 'Draft',
  schedule: {
    start: 0,
    end: 0,
    timezone: ''
  }
})  {
  const templatedExperienceAPI = new TemplatedExperienceAPI();

  return (dispatch: any, getState: any) => {
    let template: TExperienceInstance = getState().templatedExperience.template;
    template = updateEmptyContentName(template, 'Untitled');
    template.status = payload.status;
    if(template.schedule?.start &&
      template.schedule.start > moment().unix() &&
      template.status !== 'Draft'
    ) {
      template.status = 'Scheduled';
      trackEvent({
        category: 'Templated Experiences',
        action: 'Create with a schedule',
      });
    } else {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Create and Activate',
      });
    }

    return new Promise((resolve, reject) => {
      template = generateUUID(template);
      template = validateTemplatedExperience(template);
      template.name = template.name || `Untitled ${moment().format('MMM D, YYYY, hh:mm:ssa')}`;

      dispatch(setTemplate({template: template})); // update the error state
      if (template.status === 'Draft' || getIncompleteCount(template) === 0) {
        _createTemplatedInstance(template);
      }

      function _createTemplatedInstance(template: any) {
        dispatch(setLoading({flag: true}));
        templatedExperienceAPI.createTemplatedInstance(template).then(res => {
          let isDraft = template.status === 'Draft' ? ' (DRAFT)' : '';
          dispatch(showSnackbar({
            content: `Successfully created experience${isDraft}: "${template.name}"`,
          }));
          resolve(res);
        }).catch((err: any) => {
          dispatch(setError({errorMessage: err?.response?.data ? err.response.data : err.message}));
          reject();
        }).finally(() => {
          dispatch(setLoading({flag: false}));
        })
      }
    });
  }
}

export function updateInstanceSchedule(payload: { schedule: ExperienceSchedule }) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any, getState: any) => {
    let instance: TExperienceInstance = getState().templatedExperience.instance;
    return new Promise((resolve, reject) => {
      dispatch(setLoading({flag: true}));
      templatedExperienceAPI.updateTemplatedExperienceInstanceSchedule(instance.id, payload.schedule).then(res => {
        dispatch(showSnackbar({
          content: `Successfully updated schedule of the experience: "${instance.name}"`,
        }));
        resolve(res);
      }).catch((err: any) => {
        dispatch(setError({errorMessage: err?.response?.data ? err.response.data : err.message}));
        reject();
      }).finally(() => {
        dispatch(setLoading({flag: false}));
      })
    });
  }
}

export function updateTemplatedInstance(payload: { status: string } = { status: 'Draft' })  {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any, getState: any) => {
    let instance: TExperienceInstance = getState().templatedExperience.instance;
    instance.status = payload.status;
    instance.schedule = {
      ...instance.schedule,
      timezone: cleanupTimezone(instance.schedule.timezone || '')
    }
    return new Promise((resolve, reject) => {
      instance = generateUUID(instance);
      instance = validateTemplatedExperience(instance);
      instance.name = instance.name || `Untitled ${moment().format('MMM D, YYYY, hh:mm:ssa')}`;
      dispatch(setInstance({instance: instance})); // update the error state
      if (instance.status === 'Draft' || getIncompleteCount(instance) === 0) {
        _updateTemplatedInstance(instance);
      }

      function _updateTemplatedInstance(instance: any) {
        dispatch(setLoading({flag: true}));
        templatedExperienceAPI.updateTemplatedExperienceInstance(instance).then(res => {
          dispatch(showSnackbar({
            content: `Successfully updated experience: "${instance.name}"`,
          }));
          resolve(res);
        }).catch((err: any) => {
          dispatch(setError({errorMessage: err?.response?.data ? err.response.data : err.message}));
          reject();
        }).finally(() => {
          dispatch(setLoading({flag: false}));
        })
      }

    });
  }
}

export function activateTemplatedExperienceInstance(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI.activateTemplatedExperienceInstance(id)
        .then((res: any) => {
          if (res.status === 'Active' || res.status === 'Scheduled') {
            dispatch(updateInstances({id, status: res.status}));
            let content = `The experience has been successfully ACTIVATED`;
            let type = 'success';
            if(res.status === 'Scheduled') {
              content = `The experience has been successfully SCHEDULED`;
              type = 'info';
            }
            dispatch(showSnackbar({content, type}));
            resolve(res);
          }
        })
        .catch((err: any) => {
          dispatch(setError({errorMessage: err?.response?.data ? err.response.data : err.message}));
          reject();
        })
    })
  }
}

export function deactivateTemplatedExperienceInstance(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI.deactivateTemplatedExperienceInstance(id).then((res: any) => {
        if (res.status === 'Inactive') {
          dispatch(updateInstances({id, status: res.status}));
          dispatch(showSnackbar({
            content: `The experience has been DEACTIVATED`,
            type: 'default',
          }));
          resolve(res);
        }
      }).catch((err: any) => {
        dispatch(setError({errorMessage: err?.response?.data ? err.response.data : err.message}));
        reject();
      })
    })
  }
}

export function deleteTemplatedExperienceInstance(instance: TExperienceInstance, currentRowPerPage: number, currentPage: number) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI.deleteTemplatedExperienceInstance(instance.id).then((res: any) => {
          dispatch(fetchInstances({limit: currentRowPerPage, offset: currentRowPerPage * (currentPage - 1)}));
          dispatch(showSnackbar({
            content: `Successfully deleted experience: "${instance.name || 'Untitled'}"`,
            type: 'default',
          }));
          resolve(res);
      }).catch((err: any) => {
        dispatch(setError({errorMessage: err?.response?.data ? err.response.data : err.message}));
        reject();
      })
    })
  }
}

export function setAudience(payload: {ruleStringRepresentation: string, seedRuleStringRepresentation: string}) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { template, instance } = getState().templatedExperience;
    const target: TExperienceInstance = isEmpty(template) ? instance : template;
    target.steps[0].ruleStringRepresentation = payload.ruleStringRepresentation;
    target.steps[0].seedRuleStringRepresentation = payload.seedRuleStringRepresentation;
    dispatch(setTemplate({template: template}));
  }
}

export function setName(name: string) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const template: TExperienceInstance = getState().templatedExperience.template;
    template.name = name;
    dispatch(setTemplate({template: template}));
  }
}

export function setInstanceName(name: string) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const instance: TExperienceInstance = getState().templatedExperience.instance;
    instance.name = name;
    dispatch(setInstance({instance}));
  }
}

export function setContent(actionID: string, content: Content) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    _updateActionBody(actionID, () => ({
      labels: content.labels,
      contentIds: [content.id],
      localizations: content.localizations
    }))(dispatch, getState);
  }
}

// Form object to action
export function setWebLinkPush(action: any, webhook: any) {
  const payload = getPushPayload(action);
  payload.title.en = webhook.title.value;
  payload.alert.en = webhook.message.value;
  payload.data = {
    url: webhook.url.value || ''
  };
  return (dispatch: any, getState: any) => {
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  }
}

// Form object to action
export function setBasicPush(action: any, basic: any) {
  const payload = getPushPayload(action);
  payload.title.en = basic.title.value;
  payload.alert.en = basic.message.value;
  delete payload.data;
  return (dispatch: any, getState: any) => {
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  };
}

export function showError(type: 'instance' | 'template') {
  return (dispatch: any, getState: any) => {
    if (type === 'instance') {
      const instance: TExperienceInstance = getState().templatedExperience.instance;
      dispatch(setInstance({instance: {...instance, showError: true}}))
    } else {
      const template: TExperienceInstance = getState().templatedExperience.template;
      dispatch(setTemplate({template: {...template, showError: true}}));
    }
  }
}