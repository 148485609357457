
import React, { useState, useEffect } from 'react';
import UseAsyncState from 'hooks/useAsyncState';

import { times } from "lodash";

import Skeleton from 'react-loading-skeleton';
import { FlightModal, FlightButton } from '@flybits/webapp-design-system-react';
import './ListModal.scss';

// Dot notation to retrieve the value to display from individual data
// eg) dataKey = "localization.en.name" will be applied to a given item like so ['localization']['en']['name']
let dataKey: string;

interface IProps {
  icon: string;
  title: string;
  dataKey: string;
  isVisible: boolean;
  selectedId: string;
  fetchData: () => Promise<any>;
  onApply: (item: any) => void;
  onCancel: () => void;
}

function ListItem(item: any, index: number, isSelected: boolean, onSelectItem: (item: any) => void) {
  let className = "list-select-modal__content-item";
  if (isSelected) {
    className += " selected"
  }
  return (
    <button
      className={className}
      onClick={() => onSelectItem(index)}
      key={`modal__item-${index}`}
    >
      {dataKey.split('.').reduce((o, i) => o[i], item)}
    </button>
  )
}

export default function BaseListSelectModal(props: IProps) {
  dataKey = props.dataKey;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [arrData, isLoading] = UseAsyncState(() => props.fetchData());

  useEffect(() => {
    if (!arrData) return;
    if (selectedIndex === null && props.selectedId != null) {
      const index = arrData.findIndex((data: any) => data.id === props.selectedId);
      setSelectedIndex(index);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrData]);

  return (
    <FlightModal
      size="medium"
      isVisible={props.isVisible}
      scrollable={true}
      toggleModalShown={() => props.onCancel()}
      header={
        <div className="list-select-modal__header">
          <img src={require(`assets/icons/${props.icon}`)} className="list-select-modal__header-icon" alt="modal icon"/>
          <h3> {props.title} </h3>
        </div>
      }
      content={
        <div className="list-select-modal">
          <div className="list-select-modal__content">
            {arrData && (
              arrData.map((data: any, idx: number) => {
                const isSelected = idx === selectedIndex
                return ListItem(data, idx, isSelected, setSelectedIndex);
              })
            )}
          </div>
          {isLoading &&
            <div className="list-select-modal__progress">
              {times(3, (i) => (
                <div key={i} style={{ marginRight: 0}}>
                  <Skeleton width={'100%'} height={50} />
                </div>
              ))}
            </div>
          }
        </div>
      }
      footer={
        <div className="list-select-modal__footer">
          <FlightButton
            theme="secondary"
            onClick={() => props.onCancel()}
            label="Cancel"
          />
          <FlightButton
            onClick={() => props.onApply(arrData[selectedIndex!!])}
            disabled={selectedIndex == null}
            label="Apply"
          />
        </div>
      }
    />
  )
}
