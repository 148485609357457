
import React from 'react';
import "./NoAccess.scss";

export default function NoAccess() {
  return (
      <div className="no-access">
        <div className="header">
          Access Denied
        </div>
        <div className="description">
          <p> You do not have enough permissions to access Experience Studio. Please <b> contact an administrator. </b> </p>
          <p> Vous ne disposez pas des autorisations suffisantes pour accéder à Experience Studio. Veuillez <b> contacter un administrateur. </b> </p>
        </div>
    </div>
  )
}